/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  font-size: 20px;
  white-space: nowrap;
  text-transform: uppercase;
  &:hover {
  }
  &.btn-primary {
    background: $primary;
    &:hover {
      background: $primaryhover;
    }
  }
  &.btn-secondary {
    background: $black;
    color: #fff;
    &:hover {
      background: #555;
    }
  }
  &.btn-outline-primary {
    border: 2px solid $primary;
    padding: 9px 18px;
    &:hover {
      background: $primary;
    }
  }
  &.btn-outline-grey {
    border: 2px solid #808080;
    padding: 9px 18px;
    &:hover {
      background: rgba(#000, 0.08);
    }
  }
  &.btn-outline-black {
    border: 2px solid $black;
    padding: 8px 18px;
    &:hover {
      background: $black;
      color: #fff;
    }
  }
  &.btn-sm {
    padding: 8px 15px;
    // border-radius: 4px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    &[class*="btn-outline"] {
      border-width: 1px;
      padding: 4px 14px;
    }
  }
  .btn-sm[class^="btn-outline"] {
    border-width: 1px;
  }
  &.btn-block {
    width: 100%;
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-centered {
    justify-content: center;
  }
}
