/***** Hero *****/

.section-hero {
  background-size: cover;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  min-height: 916px;
  padding-top: 85px;
  display: flex;
  align-items: center;
  position: relative;
  @include max($huge) {
    min-height: 700px;
  }
  @include max($xxl) {
    padding-top: 57px;
    min-height: 600px;
  }
  @include max($lg) {
    min-height: 500px;
  }
  @include max($sm) {
    min-height: 400px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/bnr-gradient.png") 0 0 no-repeat;
    background-size: cover;
    opacity: 0.8;
    @include max($sm) {
      background-position: 30% 0;
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .hero-i {
    padding: 40px 0;
    max-width: 60%;
    @include max($sm) {
      max-width: 80%;
    }
    @include max($xxs) {
      max-width: 100%;
    }
  }
  h1 {
    line-height: 1;
    margin-bottom: 40px;
    @include max($sm) {
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 48px;
    line-height: 1.25;
    margin-bottom: 80px;
    @include max($huge) {
      font-size: 32px;
      margin-bottom: 40px;
    }
    @include max($xxl) {
      font-size: 24px;
    }
    @include max($sm) {
      margin-bottom: 20px;
    }
    strong {
      font-weight: 500;
    }
  }
  .btns {
  }

  .more {
    position: absolute;
    z-index: 2;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    @include max($lg) {
      bottom: 20px;
    }
    a {
      text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      @include max($lg) {
        font-size: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
      span {
        display: block;
        margin-bottom: 10px;
        @include max($lg) {
          margin-bottom: 5px;
        }
      }
      .icon {
        display: block;
        fill: currentColor;
        margin: 0 auto;
      }
    }
  }
}

.section-hero.section-hero--about {
  .hero-i {
    max-width: 100%;
  }
  .hero-advantages {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 150px -20px 0;
    @include max($huge) {
      margin: 130px -10px -20px;
    }
    @include max($xxl) {
      margin-top: 100px;
    }
    @include max($lg) {
      margin-top: 60px;
    }
    @include max($sm) {
      margin: 40px -10px -10px;
    }
    .adv-item {
      width: 25%;
      flex: 0 0 25%;
      padding: 0 20px;
      @include max($huge) {
        padding: 0 10px;
      }
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 10px 20px;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 10px 10px;
      }
    }
  }
}

.section-hero.section-hero--light {
  &:after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 80%
    );
  }
}

.section-hero.section-hero--career {
  align-items: flex-start;
  .hero-i {
    padding: 110px 0 40px;
  }
}
.section-hero.section-hero--company {
  align-items: flex-start;
  &:after {
    display: none;
  }
  .hero-i {
    padding: 110px 0 40px;
  }
}
