/***** Blog *****/

.section-blog {
  padding: 130px 0 190px;
  @include max($xxl) {
    padding: 80px 0 40px;
  }
  .breadcrumbs {
    margin-bottom: 45px;
    @include max($xxl) {
      margin-bottom: 25px;
    }
  }
  .blog-h {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 65px;
    @include max($xxl) {
      margin-bottom: 35px;
    }
    h1 {
      color: $primary;
      margin-right: 30px;
      line-height: 1.1;
      @include max($sm) {
        margin: 0 0 20px;
      }
    }
    .search-form {
      width: 439px;
      flex: 0 0 439px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: stretch;
      border: 1px solid #808080;
      border-radius: 8px;
      @include max($xxl) {
        width: 300px;
        flex: 0 0 300px;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      form {
        display: flex;
        align-items: center;
        justify-content: stretch;
        width: 100%;
      }
      .form-control {
        flex: 1;
        border: none;
        width: 100%;
        height: 48px;
        background: #fff;
        border-radius: 7px 0 0 7px;
        padding: 10px 15px;
        font-size: 20px;
      }
      .btn {
        width: 55px;
        height: 48px;
        flex: 0 0 55px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin-left: auto;
        border-radius: 0 7px 7px 0;
        .icon {
        }
      }
    }
  }

  .blog-featured {
    margin: -10px -10px 80px;
    @include max($xxl) {
      margin: -10px -10px 40px;
    }
    .swiper {
      padding-bottom: 34px;
      .swiper-slide {
        padding: 10px;
      }
    }
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        margin: 0 13px;
        background: #455862;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background: $primary;
          opacity: 1;
        }
      }
    }
  }

  .blog-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    @include max($xxl) {
      margin-bottom: 30px;
    }
    h2 {
      font-family: $font-heading;
      line-height: 1.1;
      font-size: 84px;
      font-weight: normal;
      margin: 0 40px 0 0;
      @include max($huge) {
        font-size: 64px;
        margin: 0 0 30px 0 0;
      }
      @include max($xxl) {
        margin: 0 0 20px;
        width: 100%;
        font-size: 48px;
      }
      @include max($sm) {
        font-size: 42px;
      }
    }

    .blog-views {
      margin-left: auto;
      display: flex;
      border: 1px solid #808080;
      border-radius: 12px;
      gap: 18px;
      @include max($huge) {
        gap: 5px;
      }
      @include max($xxl) {
        margin: 0;
      }
      .blog-view {
        display: flex;
        align-items: center;
        padding: 10px 28px 10px 20px;
        cursor: pointer;
        transition: all 0.2s;
        background: rgba(#f3f3f3, 0);
        border-radius: 11px;
        @include max($huge) {
          padding: 8px 15px 8px 10px;
        }
        svg {
          margin-right: 16px;
          flex: 0 0 23px;
        }
        span {
          font-weight: 500;
          font-size: 20px;
          text-transform: uppercase;
        }
        &.is-active {
          background: #f3f3f3;
        }
      }
    }
  }

  .blog-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 130px;
    @include max($huge) {
      margin-bottom: 60px;
    }

    @include max($lg) {
      display: block;
    }
    &.no-sidebar {
      .blog-content {
        margin-right: 0;
      }
      .blog-sidebar {
        display: none;
      }
    }
  }
  .blog-content {
    flex: 1;
    margin-right: 60px;
    @include max($xxl) {
      margin-right: 40px;
    }
    @include max($lg) {
      margin: 0 0 60px;
    }
  }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    &[data-view="list"] {
      .post-item {
        flex: 0 0 100%;
        &:not(:last-child) {
          margin-bottom: 155px;
          @include max($huge) {
            margin-bottom: 60px;
          }
        }
      }
    }
    &[data-view="grid"] {
      margin: 0 -20px -40px;
      .post-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 20px 40px;
        @include max($lg) {
          width: 100%;
          flex: 0 0 100%;
          max-width: 600px;
        }
      }
      .card.card--post {
        .card-author,
        .card-tags {
          display: none;
        }
        .card-date,
        .btns {
          display: block;
        }
      }
    }
  }
}
