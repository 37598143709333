/***** Homepage *****/

.section-hero-home {
  background: #f5f5f5;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: stretch;
  // @include max($huge) {
  //   min-height: 700px;
  // }
  // @include max($xxl) {
  //   padding-top: 57px;
  //   min-height: 600px;
  // }
  // @include max($lg) {
  //   min-height: 500px;
  // }
  // @include max($sm) {
  //   min-height: 400px;
  // }
  @include max($huge) {
    min-height: 90vh;
  }
  @include max($xxl) {
    // padding-top: 57px;
    // min-height: 600px;
  }
  // @include max($lg) {
  //   min-height: 500px;
  // }
  // @include max($sm) {
  //   min-height: 400px;
  // }
  .container {
    position: relative;
    z-index: 2;
  }
  .hero-i {
    padding: 11.5vw 0 40px;
    max-width: 41%;
    @include max($lg) {
      max-width: 60%;
      padding-top: calc(57px + 60vw);
      padding-bottom: 70px;
    }
    @include max($sm) {
      max-width: 80%;
      padding-top: calc(57px + 70vw);
    }
    @include max($xs) {
      max-width: 90%;
      padding-top: calc(57px + 75vw);
    }
    @include max($xxs) {
      max-width: 100%;
      padding-top: calc(57px + 80vw);
    }
  }
  h1 {
    line-height: 1;
    margin-bottom: calc(180px + 65px);
    @include max($huge) {
      margin-bottom: calc(120px + 60px);
    }
    @include max($xxl) {
      margin-bottom: calc(90px + 50px);
    }
    @include max($sm) {
      margin-bottom: calc(75px + 20px);
    }
  }

  .hero-links {
    .label {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      padding: 0 0 8px 12px;
      @include max($xxl) {
        padding: 0 0 6px 8px;
        font-size: 18px;
      }
      @include max($sm) {
        padding: 0 0 4px 8px;
        font-size: 16px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      font-size: 24px;
      line-height: 32px;
      @include max($xxl) {
        font-size: 20px;
        line-height: 1.2;
        gap: 6px;
      }
      @include max($sm) {
        font-size: 18px;
        gap: 4px;
      }

      li {
        margin: 0;
        padding: 0;
        background: rgba(#fff, 0.7);
        font-weight: bold;
        padding: 5px 12px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        @include max($xxl) {
          padding: 5px 8px;
        }
        &:hover {
          background: rgba(#fff, 0.9);
        }
        &.is-active {
          background: #f2bd19;
        }
        a {
        }
      }
    }
  }

  .more {
    position: absolute;
    z-index: 2;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    @include max($lg) {
      bottom: 20px;
    }
    @include max($sm) {
      bottom: 10px;
    }
    a {
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      @include max($lg) {
        font-size: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
      span {
        display: block;
        margin-bottom: 10px;
        @include max($lg) {
          margin-bottom: 5px;
        }
      }
      .icon {
        display: block;
        fill: currentColor;
        margin: 0 auto;
      }
    }
  }

  .swiper--hero-home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .swiper-slide {
      background-size: cover;
      background-position: 50% 50%;
      display: flex;
      align-items: stretch;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#fff, 0.3);
        opacity: 0;
        visibility: hidden;
        @include max($lg) {
          opacity: 1;
          visibility: visible;
        }
      }
      .container {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: stretch;
      }
      .slide-i {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .slide-desc {
        font-size: 48px;
        line-height: 1.25;
        position: absolute;
        z-index: 1;
        left: 0;
        top: calc(11.5vw + 128px + 15px);

        max-width: 41%;
        @include max($huge) {
          font-size: 32px;
          top: calc(11.5vw + 100px + 15px);
        }
        @include max($xxl) {
          font-size: 24px;
          top: calc(11.5vw + 64px + 10px);
        }
        @include max($lg) {
          max-width: 60%;
          top: calc(57px + 60vw + 64px + 10px);
        }
        @include max($sm) {
          max-width: 80%;
          font-size: 20px;
          top: calc(57px + 70vw + 48px + 5px);
        }
        @include max($xs) {
          max-width: 90%;
          top: calc(57px + 75vw + 48px + 5px);
        }
        @include max($xxs) {
          max-width: 100%;
          top: calc(57px + 80vw + 48px + 5px);
        }
        strong {
          font-weight: 500;
        }
      }
      .slide-img {
        line-height: 0;
        position: absolute;
        z-index: 0;
        right: 0;
        top: 50%;
        transform: translate(5%, -45%);
        transition: all 0.4s;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        // width: 52vw;
        // height: 52vw;
        // max-height: 100vh;
        max-width: 750px;
        @include max($huge) {
          transform: translate(0, -45%);
        }
        @include max($xxl) {
          max-width: 60%;
        }
        @include max($lg) {
          top: 57px;
          left: 50%;
          transform: translate(-50%, -10%);
          max-width: 100%;
          width: 60vw;
          height: 60vw;
        }
        @include max($sm) {
          width: 70vw;
          height: 70vw;
        }
        @include max($xs) {
          width: 75vw;
          height: 75vw;
        }
        @include max($xxs) {
          width: 80vw;
          height: 80vw;
        }
        img {
          max-height: 120%;
          max-width: 120%;
          @include max($xxl) {
            width: 120%;
            height: 120%;
            object-fit: cover;
          }
        }
      }
      &.swiper-slide-active {
        .slide-img {
          transform: translate(5%, -50%);
          opacity: 1;
          transition: all 0.8s;
          @include max($huge) {
            transform: translate(0, -50%);
          }
          @include max($lg) {
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }
}

.section-create {
  padding: 70px 0 30px;
  @include max($lg) {
    padding: 40px 0 20px;
  }
  .container {
  }
  .create-img {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    @include max($lg) {
      order: 2;
    }
    img {
    }
  }
  .create-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    @include max($xxl) {
      padding-top: 0;
    }
    @include max($lg) {
      order: 1;
      padding-bottom: 20px;
    }
    h2 {
      background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
      padding: 20px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      border-radius: 15px;
      margin-bottom: 30px;
    }
    p {
      font-size: 20px;
      @include max($lg) {
        font-size: 16px;
      }
    }
  }
}

.section-built-for {
  padding: 30px 0 70px;
  @include max($lg) {
    padding: 20px 0 30px;
  }
  .container {
  }
  .yblock-h {
    margin: 0 auto 100px;
    @include max($xxl) {
      margin-bottom: 40px;
    }
  }

  .section-body {
    padding: 94px 0 143px;
    @include max($lg) {
      padding: 0;
    }
    .built-for {
      position: relative;
      display: flex;
      align-items: stretch;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100vw;
        border-radius: 35px 0 0 35px;
        background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
        @include max($lg) {
          border-radius: 30px 0 0 30px;
        }
      }
      .built-for-l {
        padding: 70px 50px 60px 50px;
        width: 550px;
        flex: 0 0 550px;
        position: relative;
        z-index: 1;
        @include max($xxl) {
          width: 400px;
          flex: 0 0 400px;
          padding: 40px 30px 40px 40px;
        }
        @include max($lg) {
          display: none;
        }
        .label {
          font-weight: bold;
          font-size: 24px;
          margin: 0 0 14px;
          @include max($xxl) {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
        .built-for-nav {
          font-weight: bold;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .nav-item {
            font-size: 32px;
            color: #fff;
            cursor: pointer;
            transition: all 0.2s;
            @include max($xxl) {
              font-size: 24px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.is-active {
              color: #000;
              pointer-events: none;
              cursor: default;
            }
          }
        }
      }

      .built-for-r {
        position: relative;
        padding: 10px;
        z-index: 1;
        margin: -94px 0 -143px;
        min-width: 0;
        width: calc(50vw + 250px);
        flex: 0 0 calc(50vw + 250px);
        position: relative;
        overflow: hidden;
        @include max($lg) {
          // overflow: visible;
          width: 100%;
          flex: 0 0 100vw;
          margin: 0;
        }
        .built-for-r-i {
          width: 450px;
          @include max($xxl) {
            width: 400px;
          }
          @include max($sm) {
            width: 300px;
          }
        }
        .swiper--built-for {
          overflow: visible;
          @include max($lg) {
          }
        }
        .swiper-wrapper {
        }
        .swiper-slide {
          width: 450px;
          @include max($xxl) {
            width: 400px;
          }
          @include max($sm) {
            width: 300px;
          }
          &.swiper-slide-active {
            .card.card--built-for {
              background: rgba(#fff, 1);
              .card-img {
                img {
                  filter: none;
                }
              }
            }
          }
          &:last-child {
            margin-right: 340px;
          }
        }
      }
    }
  }
}

.section-netsuite {
  padding: 70px 0 80px;
  @include max($lg) {
    padding: 30px 0 40px;
  }
  .container {
  }
  .yblock-h {
    margin-bottom: 237px;
    @include max($huge) {
      margin-bottom: 100px;
    }
    @include max($lg) {
      margin-bottom: 60px;
    }
    @include max($sm) {
      margin-bottom: 40px;
    }
  }

  .section-body {
    .row {
      .netsuite-img {
        line-height: 0;
        @include max($lg) {
          margin-bottom: 40px;
        }
        img {
          border-radius: 35px;
          @include max($lg) {
            width: 100%;
            max-width: 500px;
          }
        }
      }
      .netsuite-body {
        h2 {
          font-size: 32px;
          @include max($lg) {
            font-size: 24px;
          }
          @include max($sm) {
            font-size: 20px;
          }
          strong {
            display: inline-block;
            font-size: 48px;
            background-image: linear-gradient(
              to right,
              #f5bb00 0%,
              #f5ce00 100%
            );
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            @include max($lg) {
              font-size: 36px;
            }
            @include max($sm) {
              font-size: 32px;
            }
          }
        }
        p {
        }
        .btns {
          margin-bottom: 60px;
          @include max($sm) {
            margin-bottom: 40px;
          }
          a.btn.btn-outline-primary {
          }
        }

        .netsuite-items {
          .netsuite-item {
            box-shadow: 0 0 10px rgba(#000, 0.3);
            border-radius: 18px;
            background: #fff;
            padding: 31px 40px;
            display: flex;
            align-items: center;
            @include max($lg) {
              padding: 30px;
            }
            @include max($sm) {
              padding: 20px;
            }
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            .item-icon {
              width: 60px;
              flex: 0 0 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 40px;
              @include max($lg) {
                margin-right: 30px;
              }
              @include max($sm) {
                margin-right: 20px;
              }
            }
            .item-body {
              flex: 1;
              h3 {
                font-size: 32px;
                margin: 0;
                @include max($lg) {
                  font-size: 24px;
                }
                @include max($sm) {
                  font-size: 20px;
                }
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.section-features {
  padding: 80px 0 75px;
  @include max($lg) {
    padding: 40px 0 30px;
  }
  .container {
  }
  .yblock-h {
    margin-bottom: 120px;
    @include max($xxl) {
      margin-bottom: 80px;
    }
    @include max($lg) {
      margin-bottom: 60px;
    }
    @include max($sm) {
      margin-bottom: 40px;
    }
  }

  .section-body {
    .row {
      .features-body {
        @include max($lg) {
          margin-bottom: 30px;
        }
        .feature-list {
          .feature-item {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            .card {
            }
          }
        }
      }
      .features-img {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
        }
      }
    }
  }
}

.section-testimonials {
  padding: 75px 0 100px;
  overflow-x: hidden;
  @include max($lg) {
    padding: 30px 0 40px;
  }
  .container {
  }
  .yblock-h {
    margin-bottom: 139px;
    @include max($xxl) {
      margin-bottom: 60px;
    }
    @include max($lg) {
      margin-bottom: 40px;
    }
  }

  .section-body {
    position: relative;
    padding: 50px 0;
    @include max($xxl) {
      padding: 50px 0 80px;
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1057px;
      height: 247px;
      background: url("../images/el/dots-wide-bg.png") 0 0 no-repeat;
      background-size: contain;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    .swiper.swiper--home-testimonials {
      @include max($lg) {
        overflow: visible;
        margin: 0 20px;
      }
    }
    .swiper-wrapper {
    }
    .swiper-slide {
    }
    .card {
    }

    .swiper-prev,
    .swiper-next {
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 10px;
      z-index: 1;
      cursor: pointer;
      transition: all 0.2s;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="553.3" height="969.91" viewBox="0 0 553.3 969.91"><path d="M164.94,485.1,533.3,116.73A68.35,68.35,0,1,0,436.69,20L21.46,435.42A5.69,5.69,0,0,0,20,436.74,67.63,67.63,0,0,0,0,485.1a68.59,68.59,0,0,0,20,48.36,6.8,6.8,0,0,1,1.46,1.31L436.69,949.9a68.26,68.26,0,1,0,96.61-96.46Z"/></svg>')
        50% 50% no-repeat;
      background-size: 19px 33px;
      &:hover {
        background-color: $greyf;
      }
    }
    .swiper-prev {
      bottom: 50px;
      right: 155px;
      @include max($huge) {
        bottom: 0;
        right: 100px;
      }
    }
    .swiper-next {
      bottom: 50px;
      right: 60px;
      transform: scaleX(-1);
      @include max($huge) {
        right: 20px;
        bottom: 0;
      }
    }
  }
}

.section-customers {
  padding: 100px 0 130px;
  @include max($lg) {
    padding: 40px 0 50px;
  }
  .container {
  }
  h2 {
    text-align: center;
    margin: 0 0 80px;
    @include max($xxl) {
      margin-bottom: 60px;
    }
    @include max($lg) {
      margin-bottom: 40px;
    }
    @include max($sm) {
      margin-bottom: 30px;
    }
    strong {
      background-image: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  .swiper.swiper--customers {
    overflow: visible;
    margin-bottom: 90px;
    @include max($lg) {
      margin-bottom: 40px;
      overflow: hidden;
      padding: 10px 0;
    }
    @include max($sm) {
      margin-bottom: 30px;
    }
  }
  .swiper.swiper--customers-2 {
    overflow: visible;
    margin-bottom: 90px;
    @include max($lg) {
      margin-bottom: 40px;
      overflow: hidden;
      padding: 10px 0;
    }
    @include max($sm) {
      margin-bottom: 30px;
    }
  }
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .swiper-slide {
    width: 400px;
    height: 200px;
    background: #fff;
    box-shadow: 0 0 10px rgba(#000, 0.3);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @include max($lg) {
      width: 300px;
      height: 130px;
    }
    @include max($sm) {
      border-radius: 20px;
    }
    img {
      max-width: 82%;
    }
  }

  h3 {
    text-align: center;
    font-size: 32px;
    margin: 0;
    @include max($xxl) {
      font-size: 24px;
    }
    @include max($sm) {
      font-size: 20px;
    }
  }
}
