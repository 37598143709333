/***** About *****/

.section-timeline {
  padding: 150px 0 70px;
  @include max($huge) {
    padding: 100px 0 50px;
  }
  @include max($xxl) {
    padding: 80px 0 40px;
  }
  @include max($sm) {
    padding: 60px 0 30px;
  }
  h2 {
    margin-bottom: 40px;
    @include max($xxl) {
      margin: 0 0 30px;
    }
    @include max($lg) {
      margin: 0 0 20px;
    }
  }
  .swiper--timeline {
    padding: 10px;
    margin: -10px;
    @include max($sm) {
      margin-right: 10px;
      overflow: visible;
    }
    .swiper-slide {
      height: auto;
      display: flex;
      align-items: stretch;
    }
  }

  .timeline-nav {
    display: flex;

    margin: 45px -10px 0;
    @include max($lg) {
      margin: 30px -5px 0;
    }
    @include max($sm) {
      flex-wrap: wrap;
      margin: 30px -5px -20px;
    }
    .timeline-nav-item {
      position: relative;
      flex: 0 0 25%;
      min-width: 0;
      padding: 20px 10px 5px;
      cursor: pointer;
      color: #ababab;
      transition: all 0.2s;
      @include max($lg) {
        padding: 15px 5px 0;
      }
      @include max($sm) {
        flex: 0 0 50%;
        padding: 10px 5px 20px;
        font-size: 14px;
      }
      &:hover {
        color: #888;
        &:before {
          background: #e5e5e5;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        height: 10px;
        background: #f2f2f2;
        border-radius: 5px;
        transition: all 0.2s;
        @include max($lg) {
          left: 5px;
          right: 5px;
        }
        @include max($sm) {
          height: 6px;
          border-radius: 3px;
        }
      }
      .item-t {
        font-weight: 500;
      }
      .item-b {
        strong {
          font-weight: 500;
        }
      }
      &.is-active {
        color: #000;
        cursor: default;
        &:before {
          background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
        }
      }
    }
  }
}
.section--company-slider {
  padding: 50px 0 50px;
  overflow-x: hidden;
  @include max($huge) {
    padding: 100px 0 50px;
  }
  @include max($xxl) {
    padding: 80px 0 40px;
  }
  @include max($sm) {
    padding: 60px 0 30px;
  }
  .container {
    @include max($sm) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
  h2 {
    margin-bottom: 40px;
    @include max($xxl) {
      margin: 0 0 30px;
    }
    @include max($lg) {
      margin: 0 0 20px;
    }
    @include max($sm) {
      order: 1;
    }
  }
  .swiper--company-slider {
    padding: 10px;
    margin: -10px;
    overflow: visible;
    @include max($sm) {
      margin: 0;
      overflow: visible;
      order: 3;
    }
    .swiper-slide {
      height: auto;
      display: flex;
      align-items: stretch;
    }
  }

  .company-slider-nav {
    display: flex;

    margin: 45px -10px 0;
    @include max($lg) {
      margin: 30px -5px 0;
    }
    @include max($sm) {
      flex-wrap: wrap;
      margin: 20px -5px;
      order: 2;
    }
    .company-slider-nav-item {
      position: relative;
      flex: 0 0 33.3333%;
      min-width: 0;
      padding: 20px 10px 5px;
      cursor: pointer;
      color: #ababab;
      transition: all 0.2s;
      @include max($lg) {
        padding: 15px 5px 0;
      }
      @include max($sm) {
        flex: 0 0 100%;
        padding: 10px 5px 20px;
        font-size: 14px;
      }
      &:hover {
        color: #888;
        &:before {
          background: #e5e5e5;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        height: 10px;
        background: #f2f2f2;
        border-radius: 5px;
        transition: all 0.2s;
        @include max($lg) {
          left: 5px;
          right: 5px;
        }
        @include max($sm) {
          height: 6px;
          border-radius: 3px;
        }
      }
      .item-t {
        font-weight: 500;
        font-size: 22px;
        @include max($lg) {
          font-size: 18px;
        }
        @include max($sm) {
          font-size: 16px;
        }
      }
      .item-b {
        strong {
          font-weight: 500;
        }
      }
      &.is-active {
        color: #000;
        cursor: default;
        &:before {
          background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
        }
      }
    }
  }
}

.section-about-map {
  padding: 70px 0;
  @include max($lg) {
    padding: 40px 0;
  }
  h2 {
    margin: 0 0 80px;
    @include max($huge) {
      margin: 0 0 40px;
    }
    @include max($xxl) {
      margin: 0 0 30px;
    }
    @include max($lg) {
      margin: 0 0 20px;
    }
  }

  img {
    @include max($sm) {
      margin: 0 -10px;
      width: calc(100% + 20px);
      max-width: 100vw;
    }
  }
}

.section-sectors {
  padding: 80px 0 60px;
  @include max($lg) {
    padding: 40px 0;
  }
  h2 {
    margin: 0 0 80px;
    @include max($huge) {
      margin: 0 0 40px;
    }
    @include max($xxl) {
      margin: 0 0 30px;
    }
  }
  .sector-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px -40px;
    @include max($xxl) {
      margin: 0 -10px -20px;
    }
    .sector-item {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 20px 40px;
      @include max($xxl) {
        padding: 0 10px 20px;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.section-team {
  padding: 70px 0 0;
  @include max($xxl) {
    padding: 40px 0 0;
  }
  h2 {
    margin-bottom: 50px;
    @include max($huge) {
      margin-bottom: 40px;
    }
    @include max($xxl) {
      margin-bottom: 30px;
    }
  }
  .team-body {
    padding: 94px 0 70px;
    @include max($xxl) {
      padding: 60px 0 50px;
    }
    @include max($sm) {
      padding: 40px 0 30px;
    }
  }
  .team-w1 {
    position: relative;
    display: flex;
    align-items: stretch;
    height: 435px;
    padding-left: 56px;
    @include max($xxl) {
      height: 400px;
      padding-left: 40px;
    }
    @include max($lg) {
      height: 350px;
    }
    @include max($sm) {
      height: 300px;
      padding-left: 20px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100vw;
      border-radius: 35px 0 0 35px;
      background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
      @include max($lg) {
        border-radius: 30px 0 0 30px;
      }
    }
  }
  .team-w2 {
    margin: -94px 0 -70px;
    width: calc(50vw + 744px);
    flex: 0 0 calc(50vw + 744px);
    @include max($xxl) {
      margin: -60px 0 -50px;
    }
    @include max($sm) {
      margin: -40px 0 -30px;
    }
  }
  .team-w3 {
    width: 450px;
    @include max($xxl) {
      width: 400px;
    }
    @include max($lg) {
      width: 350px;
    }
    @include max($sm) {
      width: 300px;
    }
  }
  .swiper--team {
    // padding: 10px;
    // margin: -10px;
    overflow: visible;
    .swiper-slide {
      width: 450px;
      @include max($xxl) {
        width: 400px;
      }
      @include max($lg) {
        width: 350px;
      }
      @include max($sm) {
        width: 300px;
      }
      &.swiper-slide-active {
        .card.card--person {
          background: rgba(#fff, 1);
          .card-img {
            img {
              filter: none;
            }
          }
        }
      }
    }
  }
  .swiper-nav {
    padding: 140px 60px 0 0;
    justify-content: flex-end;
    @include max($xxl) {
      padding: 100px 20px 0 0;
    }
    @include max($sm) {
      padding: 90px 0 0 0;
    }
  }
}
.section-related-articles.section-related-articles--about {
  padding: 70px 0 80px;
  @include max($xxl) {
    padding: 40px 0 60px;
  }
  h2 {
    margin-bottom: 40px;
  }
}

.section-featured {
  padding: 80px 0 160px;
  @include max($xxl) {
    padding: 40px 0 60px;
  }
  @include max($lg) {
    padding: 40px 0;
  }
}
