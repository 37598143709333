/***** Career *****/

.section-top-employer {
  padding: 100px 0 80px;
  @include max($xxl) {
    padding: 60px 0 40px;
  }
  @include max($sm) {
    padding: 40px 0 30px;
  }
  h2 {
    font-size: 32px;
    @include max($lg) {
      font-size: 24px;
    }
    @include max($sm) {
      font-size: 20px;
    }
    strong {
      font-size: 48px;
      @include max($lg) {
        font-size: 36px;
      }
      @include max($sm) {
        font-size: 32px;
      }
    }
  }
  p {
    font-size: 20px;
    @include max($lg) {
      font-size: 16px;
    }
  }

  .benefit-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 80px -20px -40px;
    @include max($xxl) {
      margin: 30px -10px -20px;
    }
    .benefit-item {
      padding: 0 20px 40px;
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      align-items: stretch;
      @include max($xxl) {
        padding: 0 10px 20px;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.section-career-team {
  padding: 70px 0 70px;
  overflow-x: hidden;
  @include max($xxl) {
    padding: 40px 0;
  }
  @include max($sm) {
    padding: 30px 0;
  }
  h2 {
    text-align: center;
    margin: 0 0 90px;
    @include max($huge) {
      margin-bottom: 50px;
    }
    @include max($lg) {
      margin-bottom: 30px;
    }
  }

  .career-team {
    margin-bottom: 90px;
    @include max($huge) {
      margin-bottom: 50px;
    }
    @include max($lg) {
      margin-bottom: 30px;
    }
    .swiper--career-team {
      overflow: visible;
      .swiper-slide {
        width: 47%;
        @include max($md) {
          width: 70%;
        }
        @include max($sm) {
          width: 80%;
        }
      }
      img {
        border-radius: 25px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
        @include max($sm) {
          border-radius: 15px;
        }
      }
    }
  }

  .swiper-nav {
    padding: 0px 60px 0 0;
    @include max($xxl) {
      padding: 0 20px 0 0;
    }
    @include max($sm) {
      padding: 0 0 0 0;
    }
  }
  .career-team-txt {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin: -54px 0 0;
    @include max($lg) {
      font-size: 24px;
    }
    @include max($md) {
      margin: 20px auto 0;
    }
    @include max($sm) {
      font-size: 20px;
    }
  }
}

.section-roles {
  padding: 60px 0 160px;
  @include max($xxl) {
    padding: 40px 0 60px;
  }
  @include max($lg) {
    padding: 40px 0;
  }
  .roles-h {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 2px solid $primary;
    margin-bottom: 65px;
    @include max($huge) {
      margin-bottom: 40px;
    }
    @include max($lg) {
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    @include max($sm) {
      display: block;
    }
    h2 {
      margin: 0 30px 0 0;
      @include max($sm) {
        margin: 0 0 10px;
      }
    }
    .roles-filter {
      margin-left: auto;
      width: 268px;
      @include max($lg) {
        width: auto;
      }
      @include max($sm) {
        margin: 0;
      }
    }
  }

  .role-list {
    .role-item {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
