/***** Header *****/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  // background: rgba(#fff, 0.9);
  padding: 19px 0 17px;
  z-index: 10;
  backdrop-filter: blur(5px);
  transition: all 0.2s;
  @include max($xxl) {
    padding: 10px 0;
    height: auto;
  }
  .header-i {
    display: flex;
    align-items: center;
  }
  .logo {
    padding-right: 30px;
    a {
      line-height: 0;
      display: block;
    }
    img {
      width: 142px;
    }
  }
  .header-r {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include max($xxl) {
      flex-direction: column;
      align-items: stretch;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 57px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - 57px);

      z-index: 1;
      padding: 25px 20px 30px;
      pointer-events: none;
      transition: all 0.2s;
      overflow-y: auto;
    }
    @include max($sm) {
      padding: 20px 15px;
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.9);
      backdrop-filter: blur(5px);
      z-index: -1;
      @include max($xxl) {
        display: block;
      }
    }
  }
  .header-menu {
    > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 14px;
      @include max($huge) {
        gap: 10px;
      }
      @include max($xxl) {
        flex-direction: column;
        // gap: 0;
        align-items: stretch;
      }
      > li {
        margin: 0;
        padding: 6px 0;
        @include max($xxl) {
          padding: 0;
        }
        > a,
        > span {
          display: block;
          white-space: nowrap;
          position: relative;
          padding: 8px 21px 8px 36px;
          border-radius: 8px;
          color: #000;
          font-weight: bold;
          line-height: 20px;
          @include max($huge) {
            padding: 5px 15px 5px 25px;
            font-size: 14px;
          }
          @include max($xxl) {
            font-size: 14px;
            font-weight: 500;
            // padding: 12px 12px 12px 33px;
            padding: 8px 11px 8px 36px;
          }
          @include max($lg) {
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 11px;
            left: 15px;
            width: 12px;
            height: 12px;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="947.5" height="946.7" viewBox="0 0 947.5 946.7"><path d="M947.66,473.35,474,946.7.16,473.35,474,0Zm-235.66,0L474,235,235.83,473.35,474,711.67Z"/></svg>')
              50% 50% no-repeat;
            background-size: contain;
            @include max($huge) {
              left: 7px;
              top: 8px;
            }
            @include max($xxl) {
              left: 12px;
              top: 12px;
            }
          }
        }
        > a {
          &:hover {
            background: $greye;
            @include max($xxl) {
              background: $greyf;
            }
            &:before {
              background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="947.5" height="946.7" viewBox="0 0 947.5 946.7"><path d="M473.84,0,947.5,473.35,473.84,946.7,0,473.35"/></svg>')
                50% 50% no-repeat;
              background-size: contain;
            }
          }
        }
        > span {
          background: $greyf;
          cursor: default;
          @include max($lg) {
            font-weight: bold;
          }
        }
        &.dropdown {
          position: relative;

          > a {
            &:hover {
              @include max($xxl) {
                background: $greyf;
              }
            }
          }
          > ul {
            min-width: 293px;
            position: absolute;
            top: 47px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 3px 11px rgba(#000, 0.14);
            padding: 5px 0;
            margin: 0;
            list-style: none;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            @include max($xxl) {
              position: relative;
              top: auto;
              left: auto;
              transform: none;
              border-radius: 0;
              border-radius: 8px;
              // box-shadow: none;
              padding: 0;
              max-height: 0;
              overflow-y: hidden;
            }
            > li {
              margin: 0;
              > a,
              > span {
                padding: 9px 16px;
                display: flex;
                align-items: center;
                gap: 16px;
                @include max($xxl) {
                  padding: 8px 11px 8px 14px;
                  gap: 8px;
                  border-radius: 8px;
                }
                .icon-w {
                  width: 32px;
                  flex: 0 0 32px;
                  height: 32px;
                  background: $primary;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @include max($xxl) {
                    width: 12px;
                    flex: 0 0 12px;
                    height: 12px;
                    background: none;
                  }
                  .icon {
                    &.icon-phone {
                      width: 11px;
                    }
                  }
                }
                .item-txt {
                  flex: 1;
                  .item-title {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 21px;
                    display: block;
                    white-space: nowrap;
                    @include max($xxl) {
                      font-size: 14px;
                    }
                  }
                  .item-subtitle {
                    font-size: 12px;
                    color: $grey;
                    line-height: 16px;
                    display: block;
                    @include max($xxl) {
                      display: none;
                    }
                  }
                }
              }
              > a {
                &:hover {
                  background: $greyf;
                }
              }
            }
          }
          &:hover {
            > a,
            > span {
              background: $greyf;
              @include max($xxl) {
                // background: none;
              }
            }
            > ul {
              opacity: 1;
              visibility: visible;
              @include max($xxl) {
                opacity: 0;
                visibility: hidden;
              }
            }
          }
          &.is-m-expanded {
            > a,
            > span {
              background: $greyf;
              &:hover {
                background: $greyf;
              }
            }
            > ul {
              opacity: 1;
              visibility: visible;
              max-height: 500px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .header-btns {
    margin-left: 29px;
    display: flex;
    align-items: center;
    gap: 20px;
    @include max($huge) {
      margin-left: 20px;
      gap: 10px;
    }
    @include max($xxl) {
      padding: 10px 0 15px;
      margin: 0;
      flex-direction: column-reverse;
      gap: 15px;
      margin-top: auto;
    }
    @include max($lg) {
    }
    .btn {
      @include max($huge) {
        font-size: 14px;
      }
      @include max($xxl) {
        font-size: 16px;
        min-width: 140px;
        padding: 8px;
        width: 100%;
      }
      &.btn-primary {
        @include max($xxl) {
          background: #000;
          color: #fff;
        }
        &:hover {
          @include max($xxl) {
            background: #333;
          }
        }
      }
      &.btn-secondary {
        @include max($xxl) {
          background: $primary;
          color: #000;
        }
        &:hover {
          @include max($xxl) {
            background: $primaryhover;
          }
        }
      }
    }
  }
  .header-lang {
    margin-left: 20px;
    position: relative;
    @include max($huge) {
      margin-left: 10px;
    }
    @include max($lg) {
      margin: 0;
    }
    .lang-btn {
      display: flex;
      align-items: center;
      padding: 7px 9px;
      @include max($xxl) {
        padding: 7px 16px 7px 12px;
      }
      .icon-lang {
        margin-right: 6px;
        width: 14px;
        flex: 0 0 14px;
        height: 20px;
      }
      .lang-curr {
        min-width: 30px;
        margin-right: 4px;
        @include max($huge) {
          font-size: 14px;
        }
        @include max($xxl) {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .icon-angle-down {
        flex: 0 0 13px;
        @include max($xxl) {
          margin-left: auto;
          flex: 0 0 12px;
          width: 12px;
          height: 7px;
        }
      }
    }

    .lang-dropdown {
      position: absolute;
      top: 41px;
      right: 0;
      opacity: 0;
      visibility: hidden;
      left: auto;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 3px 11px rgba(#000, 0.14);
      padding: 5px;
      margin: 0;
      transition: all 0.3s;
      @include max($xxl) {
        position: fixed;
        top: auto;
        max-height: 80vh;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 16px 16px 0 0;
        z-index: 12;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0;
      }

      .lang-dropdown-h {
        display: none;
        align-items: center;
        padding: 16px 20px;
        flex: 0 0 64px;
        @include max($xxl) {
          display: flex;
        }
        .lang-dropdown-txt {
          padding-right: 20px;
          font-size: 20px;
          font-weight: bold;
        }
        .lang-dropdown-close {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          width: 32px;
          height: 32px;
          position: relative;
          cursor: pointer;
        }
      }
      .lang-dropdown-body {
        overflow-y: auto;
        flex: 1;
        @include max($xxl) {
          padding: 0 0 20px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        @include max($xxl) {
          padding: 0 16px;
          gap: 10px;
        }
        > li {
          margin: 0;
          > a,
          > span {
            padding: 2px 14px 2px 8px;
            font-size: 16px;
            display: flex;
            gap: 8px;
            border-radius: 8px;
            align-items: center;
            font-weight: bold;
            @include max($xxl) {
              padding: 5px 11px;
              font-weight: 500;
            }
            .lang-flag {
              flex: 0 0 12px;
            }
            .lang-title {
              flex: 1;
              padding-right: 20px;
            }
          }
          > a {
            &:hover {
              background: $greyf;
            }
          }
          > span {
            color: #666;
            background: $greyf;
            cursor: default;
          }
        }
      }
    }

    &.is-active {
      .lang-btn {
        .icon-angle-down {
          transform: rotate(180deg);
        }
      }
      .lang-dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
    .lang-dropdown-bg {
      position: fixed;
      transition: all 0.2s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.5);
      z-index: 11;
      transition: all 0.2s;
      &.is-active {
        @include max($xxl) {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
    }
  }

  .header-toggler {
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 32px;
    height: 32px;
    position: relative;
    cursor: pointer;
    @include max($xxl) {
      display: flex;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &.icon-menu {
        width: 20px;
        height: 18px;
      }
      &.icon-close {
        opacity: 0;
        visibility: hidden;
        width: 18px;
        height: 18px;
      }
    }
  }
}

body.is-menu-expanded {
  overflow-y: hidden;
  .header {
    @include max($xxl) {
      background: #fff !important;
      backdrop-filter: none;
    }
    .header-r {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    .header-toggler {
      .icon {
        &.icon-menu {
          opacity: 0;
          visibility: hidden;
        }
        &.icon-close {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

body.is-scrolled,
body.is-header-white {
  .header {
    background: rgba(#fff, 0.9);
    backdrop-filter: blur(5px);
  }
}

body.page--home {
  .header {
    background: none;
    backdrop-filter: none;
  }
  &.is-scrolled {
    .header {
      background: rgba(#fff, 0.9);
      backdrop-filter: blur(5px);
    }
  }
}
