/***** Post *****/

.breadcrumbs {
  margin-bottom: 35px;
  font-size: 20px;
  @include max($xxl) {
    font-size: 16px;
    margin-bottom: 20px;
  }
  ol {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      &:not(:last-child) {
        &:after {
          content: "\\";
          margin: 0 4px 0 0;
        }
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.section-post {
  padding: 110px 0 70px;
  @include max($xxl) {
    padding: 80px 0 40px;
  }
  .post-h {
    .blog-date {
      font-size: 20px;
      margin-bottom: 20px;
      display: block;
      @include max($xxl) {
        font-size: 16px;
      }
    }

    h1 {
      font-size: 84px;
      line-height: 1;
      margin-bottom: 35px;
      max-width: 1300px;
      @include max($xxl) {
        font-size: 64px;
      }
      @include max($sm) {
        font-size: 48px;
      }
    }

    .post-tags {
      display: flex;
      align-items: flex-start;
      margin-bottom: 65px;
      @include max($xxl) {
        margin-bottom: 30px;
      }
      .label {
        font-size: 20px;
        margin-right: 9px;
        @include max($xxl) {
          font-size: 16px;
        }
      }
    }
    .tag-list {
      a {
      }
    }
  }

  .post-img {
    position: relative;
    border-radius: 28px;
    overflow: hidden;
    margin-bottom: 65px;
    @include max($xxl) {
      border-radius: 20px;
      margin-bottom: 40px;
    }
    .img-bg {
      z-index: -0;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      height: 100%;
      width: 100%;
      filter: blur(20px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    img {
      position: relative;
      display: block;
      margin: 0 auto;
      z-index: 1;
      @include max($xxl) {
        max-height: 500px;
      }
    }
  }

  .post-author {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    @include max($xxl) {
      margin-bottom: 30px;
    }
    .author-userpic {
      flex: 0 0 65px;
      display: block;
      line-height: 0;
      margin-right: 20px;
      img {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .author-info {
      flex: 1;
      font-size: 20px;
      line-height: 1.2;
      @include max($xxl) {
        font-size: 16px;
      }
      .author-label {
      }

      .author-content {
        strong {
          font-weight: 500;
        }
      }
    }
  }
  .post-row {
    display: flex;
    align-items: flex-start;
    @include max($lg) {
      display: block;
    }
  }

  .post-content {
    flex: 1;
    margin-right: 60px;
    @include max($xxl) {
      margin-right: 40px;
    }
    @include max($lg) {
      margin: 0 0 40px;
    }
    .post-body {
      .post-top {
        font-size: 20px;
      }
      p {
        margin-bottom: 1.5em;
      }

      .img {
        margin: 60px 0;
        text-align: center;
        @include max($lg) {
          margin: 40px 0;
        }
      }

      h2 {
        margin: 50px 0 30px;
        display: block;
        @include max($lg) {
          margin: 40px 0 20px;
        }
      }
    }

    .img-enlarge {
      position: relative;
      margin: 60px 0;
      padding: 54px 0;
      @include max($lg) {
        margin: 40px 0;
        padding: 40px 0;
      }
      &:before,
      &:after {
        z-index: 0;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 698px;
        height: 246px;
        background: url("../images/el/dots-narrow-bg.png") 0 0 no-repeat;
        max-width: 70%;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      .enlarge-i {
        position: relative;
        padding: 20px;
        text-align: center;
        line-height: 0;
        z-index: 1;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(#000, 0.17);
        a.enlarge-img {
          display: block;
          line-height: 0;
          &:hover {
            opacity: 0.9;
          }
          img {
          }
        }

        a.enlarge-icon {
          position: absolute;
          width: 35px;
          height: 35px;
          padding: 5px;
          right: 22px;
          bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            opacity: 0.8;
          }
          svg {
            &.icon-enlarge {
            }
          }
        }
      }
    }

    .cta-block {
      margin: 90px 0;
      @include max($xxl) {
        margin: 40px 0;
      }
      &--btn {
        .cta-txt {
          h3 {
          }

          p {
          }
        }

        a.btn {
        }
      }
    }

    .post-share {
      h3 {
        margin: 0 0 40px;
        @include max($xxl) {
          margin: 0 0 20px;
        }
      }
      .share-list {
        display: flex;
        align-items: center;
        gap: 10px;
        a {
          background: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 3px;
          &:hover {
            background: $primaryhover;
          }
          .icon {
            max-width: 20px;
            max-height: 20px;
            fill: currentColor;
            &.icon-x {
              width: 20px;
              height: 20px;
            }
            &.icon-facebook {
              max-height: 18px;
            }
          }
        }
      }
    }
  }
}

.blog-sidebar {
  width: 37.75%;
  flex: 0 0 37.75%;
  @include max($xxxl) {
    width: 30%;
    flex: 0 0 30%;
  }
  .blog-categories {
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(#000, 0.17);
    padding: 60px 0 50px;
    margin: 0 0 65px;
    @include max($xxxl) {
      border-radius: 20px;
      padding: 30px 0;
      margin: 0 0 40px;
    }
    @include max($sm) {
      margin: 0 0 20px;
    }
    h2 {
      padding: 0 80px;
      font-size: 32px;
      color: $primary;
      margin: 0 0 40px;
      @include max($xxxl) {
        padding: 0 30px;
        margin: 0 0 20px;
      }
      @include max($sm) {
        padding: 0 20px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 20px;
      @include max($xxxl) {
        font-size: 16px;
      }
      li {
        margin: 0;
        padding: 0;
        a,
        > div {
          display: block;
          padding: 10px 40px 10px 108px;
          line-height: 1.3;
          @include max($xxxl) {
            padding: 8px 20px 8px 52px;
          }
          @include max($sm) {
            padding: 8px 20px 8px 40px;
          }
          .cat-title {
            font-weight: 500;
            position: relative;
            &:before {
              content: "";
              display: block;
              position: absolute;
              left: -28px;
              top: 8px;
              width: 12px;
              height: 12px;
              background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="947.5" height="946.7" viewBox="0 0 947.5 946.7"><path d="M947.66,473.35,474,946.7.16,473.35,474,0Zm-235.66,0L474,235,235.83,473.35,474,711.67Z"/></svg>')
                50% 50% no-repeat;
              background-size: contain;
              background-size: contain;
              @include max($xxxl) {
                left: -20px;
              }
            }
          }
          .cat-desc {
            color: $grey;
          }
          &:hover {
            background: #fafafa;
          }
        }
        .is-active {
          background: #f2f2f2;
        }
      }
    }
  }

  .blog-tags {
    padding: 60px 80px 70px;
    background: #fafafa;
    border-radius: 16px;
    @include max($xxxl) {
      padding: 30px 30px;
    }
    @include max($sm) {
      padding: 30px 20px;
    }
    h2 {
      font-size: 32px;
      margin: 0 0 40px;
      @include max($xxxl) {
        margin: 0 0 20px;
      }
    }
    .tag-list {
    }
  }
}

.section-related-articles {
  padding: 60px 0 130px;
  @include max($xxl) {
    padding: 40px 0 60px;
  }
  @include max($lg) {
    padding: 40px 0;
  }
  .container {
    h2 {
      margin: 0 0 50px;
      @include max($xxl) {
        margin: 0 0 30px;
      }
    }

    .post-list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 -20px -40px;
      .post-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 20px 40px;
        display: flex;
        align-items: stretch;
        @include max($lg) {
          width: 100%;
          flex: 0 0 100%;
          max-width: 600px;
        }
      }
    }
  }
}
