$primary: #f2bd19;
$primaryhover: #fdcc38;
$grey: #808080;
$greyf: #f2f2f2;
$greye: #e5e5e5;
$black: #353535;

$font-heading: "Bebas Neue", sans-serif;
$font-basic: "Roboto", sans-serif;

$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
