/***** Blog *****/

.section-blog-list {
  padding: 122px 0 80px;
  @include max($xxl) {
    padding: 80px 0 40px;
  }

  .blog-featured {
    margin-bottom: 45px;
    @include max($lg) {
      margin-bottom: 30px;
    }
    h2 {
      margin: 0 0 25px;
      font-size: 36px;
      font-weight: 500;
      @include max($xxl) {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }
    .swiper {
      padding-bottom: 50px;
      .swiper-slide {
      }
    }
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        margin: 0 10px;
        background: #979797;
        opacity: 1;
        vertical-align: top;
        &.swiper-pagination-bullet-active {
          background: $primary;
          opacity: 1;
        }
      }
    }
  }

  .blog-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #353535;
    @include max($xxl) {
      margin-bottom: 30px;
    }
    @include max($lg) {
      display: block;
    }
    .blog-filters {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;
      margin-right: 40px;
      @include max($lg) {
        margin: 0 0 10px;
        gap: 10px 20px;
      }
    }

    .search-form {
      width: 360px;
      flex: 0 0 360px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: stretch;
      border: 1px solid #808080;
      border-radius: 10px;
      @include max($xxl) {
        width: 300px;
        flex: 0 0 300px;
      }
      @include max($lg) {
        width: 100%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      form {
        display: flex;
        align-items: center;
        justify-content: stretch;
        width: 100%;
      }
      .form-control {
        flex: 1;
        border: none;
        width: 100%;
        height: 58px;
        background: #fff;
        border-radius: 9px 0 0 9px;
        padding: 10px 15px;
        font-size: 20px;
        @include max($xxl) {
          height: 52px;
        }
      }
      .btn {
        width: 58px;
        height: 58px;
        flex: 0 0 58px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin-left: auto;
        border-radius: 0 9px 9px 0;
        @include max($xxl) {
          width: 52px;
          height: 52px;
          flex: 0 0 52px;
        }
        .icon {
        }
      }
    }

    .blog-views {
      margin-left: 50px;
      display: flex;
      border: 1px solid #808080;
      border-radius: 10px;
      @include max($xxl) {
        margin-left: 20px;
      }
      @include max($lg) {
        display: none;
      }
      .blog-view {
        display: flex;
        align-items: center;
        padding: 11px 28px 11px 20px;
        line-height: 36px;

        cursor: pointer;
        color: #979797;
        transition: all 0.2s;
        background: rgba(#f3f3f3, 0);
        border-radius: 11px;
        @include max($xxl) {
          line-height: 32px;
        }

        .icon-w {
          margin-right: 16px;
          width: 24px;
          height: 24px;
          flex: 0 0 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: currentColor;
          }
        }
        span {
          font-size: 24px;
          @include max($xxl) {
            font-size: 20px;
          }
        }
        &.is-active {
          background: #f3f3f3;
          color: #353535;
        }
      }
    }
  }

  .blog-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 130px;
    @include max($huge) {
      margin-bottom: 60px;
    }

    @include max($lg) {
      display: block;
    }
    &.no-sidebar {
      .blog-content {
        margin-right: 0;
      }
      .blog-sidebar {
        display: none;
      }
    }
  }
  .blog-content {
    flex: 1;
    margin-right: 60px;
    @include max($xxl) {
      margin-right: 40px;
    }
    @include max($lg) {
      margin: 0 0 60px;
    }
  }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    &[data-view="list"] {
      .post-item {
        flex: 0 0 100%;
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }
    }
    &[data-view="grid"] {
      margin: 0 -20px -40px;
      .post-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 20px 40px;
        display: flex;
        @include max($lg) {
          width: 100%;
          flex: 0 0 100%;
          max-width: 600px;
        }
      }
    }
  }
}
