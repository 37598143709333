/***** Components, common blocks *****/

.yblock-h {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  .yblock-body {
    background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
    border-radius: 30px;
    padding: 40px 75px;
    color: #fff;
    max-width: 1200px;
    margin: 0 auto;
    @include max($lg) {
      padding: 30px;
      border-radius: 20px;
    }
    @include max($sm) {
      padding: 20px;
    }

    h2 {
      margin: 0 auto;
      line-height: 1.25;
      @include max($sm) {
        font-size: 24px;
      }
      strong {
        color: #000;
      }
    }
  }
  &.yblock-h--arrows {
    padding: 78px 0;
    max-width: 1370px;
    @include max($xxl) {
      padding: 50px;
    }
    @include max($sm) {
      padding: 40px 16px;
    }
    .yblock-body {
      h2 {
        max-width: 880px;
      }
    }
    .yblock-arrows-bg {
      .yblock-arrow {
        width: 46px;
        height: 46px;
        background: url("../images/icons/arrow-tl.svg") 0 0 no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        @include max($xxl) {
          width: 32px;
          height: 32px;
        }
        @include max($sm) {
          width: 24px;
          height: 24px;
        }
        &.yblock-arrow--1 {
          top: 0;
          left: 0;
          animation: yb-br-animation 2s infinite ease-in-out;
          @include max($lg) {
            animation: yb-br-animation-m 2s infinite ease-in-out;
          }
        }
        &.yblock-arrow--2 {
          top: 0;
          right: 0;
          transform: rotate(90deg);
          animation: yb-bl-animation 2s infinite ease-in-out;
          @include max($lg) {
            animation: yb-bl-animation-m 2s infinite ease-in-out;
          }
        }
        &.yblock-arrow--3 {
          bottom: 0;
          left: 0;
          transform: rotate(-90deg);
          animation: yb-tr-animation 2s infinite ease-in-out;
          @include max($lg) {
            animation: yb-tr-animation-m 2s infinite ease-in-out;
          }
        }
        &.yblock-arrow--4 {
          bottom: 0;
          right: 0;
          transform: rotate(180deg);
          animation: yb-tl-animation 2s infinite ease-in-out;
          @include max($lg) {
            animation: yb-tl-animation-m 2s infinite ease-in-out;
          }
        }
      }
    }
  }
  &.yblock-h--dots {
    max-width: 1460px;
    margin: 0 auto;

    @include max($xxl) {
      padding: 0 50px;
    }

    @include max($sm) {
      padding: 0 16px;
    }
    .yblock-body {
      h2 {
      }
      strong {
      }
    }
    .yblock-dots-bg {
      .yblock-dots-item {
        position: absolute;
        &.yblock-dots-item--1 {
          left: 0;
          top: 41px;
          background: url("../images/el/192x256.gif") 0 0 no-repeat;
          background-size: contain;
          width: 96px;
          height: 126px;
          @include max($lg) {
            width: 70px;
            height: 80px;
            top: -20px;
            left: -10px;
          }
        }
        &.yblock-dots-item--2 {
          right: 0;
          top: 38px;
          background: url("../images/el/192x256.gif") 0 0 no-repeat;
          transform: rotate(180deg);
          background-size: contain;
          width: 96px;
          height: 126px;
          @include max($lg) {
            width: 70px;
            height: 80px;
            top: auto;
            bottom: -20px;
            right: -10px;
          }
        }
      }
    }
  }
  &.yblock-h--lines {
    padding: 47px 50px;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    @include max($xxl) {
      padding: 3vw 50px;
      overflow-x: hidden;
    }
    @include max($sm) {
      padding: 20px 16px;
    }
    .yblock-lines-body {
      h2 {
        strong {
        }
      }
    }
    .yblock-lines-bg {
      .yblock-line-item {
        background: url("../images/el/line-1.svg") 0 0 no-repeat;
        background-size: contain;
        width: 1564px;
        height: 253px;
        position: absolute;
        &.yblock-line-item--1 {
          top: 0;
          right: 50px;
          @include max($sm) {
            right: 16px;
          }
        }
        &.yblock-line-item--2 {
          transform: rotate(180deg);
          bottom: 0;
          left: 50px;
          @include max($sm) {
            left: 16px;
          }
        }
      }
    }
  }

  &.yblock-h--rounded {
    padding: 60px;
    max-width: 1322px;
    margin: 0 auto;
    @include max($lg) {
      padding: 40px;
    }
    .yblock-rounded-body {
      h2 {
        br {
        }
        strong {
        }
      }
    }
    .yblock-rounded-bg {
      .yblock-rounded-item {
        position: absolute;

        &.yblock-rounded-item--1 {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px solid $primary;
          border-radius: 48px;
          @include max($lg) {
            border-width: 1px;
            border-radius: 42px;
          }
        }
        &.yblock-rounded-item--2 {
          top: 29px;
          bottom: 29px;
          left: 29px;
          right: 29px;
          border: 3px solid $primary;
          border-radius: 42px;
          @include max($lg) {
            top: 15px;
            bottom: 15px;
            left: 15px;
            right: 15px;
            border-width: 2px;
            border-radius: 36px;
          }
        }
        &.yblock-rounded-item--3 {
          top: 49px;
          bottom: 49px;
          left: 49px;
          right: 49px;
          border: 4px solid $primary;
          border-radius: 36px;
          @include max($lg) {
            top: 30px;
            bottom: 30px;
            left: 30px;
            right: 30px;
            border-width: 3px;
            border-radius: 28px;
          }
        }
      }
    }
  }
}

@keyframes yb-br-animation {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
}
@keyframes yb-br-animation-m {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px);
  }
}

@keyframes yb-bl-animation {
  0%,
  100% {
    transform: translate(0, 0) rotate(90deg);
  }
  50% {
    transform: translate(-20px, 20px) rotate(90deg);
  }
}
@keyframes yb-bl-animation-m {
  0%,
  100% {
    transform: translate(0, 0) rotate(90deg);
  }
  50% {
    transform: translate(-10px, 10px) rotate(90deg);
  }
}
@keyframes yb-tr-animation {
  0%,
  100% {
    transform: translate(0, 0) rotate(-90deg);
  }
  50% {
    transform: translate(20px, -20px) rotate(-90deg);
  }
}
@keyframes yb-tr-animation-m {
  0%,
  100% {
    transform: translate(0, 0) rotate(-90deg);
  }
  50% {
    transform: translate(10px, -10px) rotate(-90deg);
  }
}
@keyframes yb-tl-animation {
  0%,
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
  50% {
    transform: translate(-20px, -20px) rotate(180deg);
  }
}
@keyframes yb-tl-animation-m {
  0%,
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
  50% {
    transform: translate(-10px, -10px) rotate(180deg);
  }
}

.card.card--built-for {
  background: rgba(#fff, 0.9);
  padding: 40px 40px 30px;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  border-radius: 20px;
  @include max($xxl) {
    padding: 30px 30px 20px;
  }
  @include max($sm) {
    padding: 20px;
  }
  .card-img {
    margin-bottom: 22px;
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 0.9;
      }
      img {
        border-radius: 20px;
        transition: all 0.2s;
        filter: grayscale(100%);
        width: 100%;
      }
    }
  }
  .card-body {
    .card-title {
      background: #f2bd19;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      padding: 3px 8px;
      line-height: 24px;
      display: inline-block;
      margin-bottom: 16px;
    }
    .card-desc {
      margin-bottom: 16px;
    }
    .card-btn {
      a.btn.btn-outline-primary {
      }
    }
  }
}

.card.card--feature {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  display: flex;
  align-items: center;
  padding: 20px;
  @include max($sm) {
    border-radius: 20px;
  }
  .card-icon {
    width: 160px;
    flex: 0 0 160px;
    height: 160px;
    border-radius: 23px;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding: 15px;
    @include max($lg) {
      padding: 10px;
      width: 120px;
      flex: 0 0 120px;
      height: 120px;
      border-radius: 20px;
    }
    @include max($sm) {
      width: 80px;
      flex: 0 0 80px;
      height: 80px;
      border-radius: 16px;
      margin-right: 10px;
    }
  }
  .card-body {
    flex: 1;
    .plus {
      font-size: 32px;
      line-height: 1;
      font-weight: bold;
      @include max($lg) {
        font-size: 24px;
      }
      @include max($sm) {
        font-size: 20px;
        display: inline;
        margin-right: 4px;
      }
    }
    h3 {
      font-size: 32px;
      margin: 0 0 3px;
      line-height: 1;
      @include max($lg) {
        font-size: 24px;
      }
      @include max($sm) {
        font-size: 20px;
        display: inline;
      }
    }
    p {
      margin: 0;
    }
  }
}

.card.card--testimonial {
  display: flex;
  align-items: flex-start;
  .card-img {
    line-height: 0;
    @include max($lg) {
      margin-bottom: -40px;
    }
    img {
      width: 100%;
      border-radius: 38px;
      @include max($lg) {
        border-radius: 20px;
      }
    }
  }
  .col-lg-6 {
    position: relative;
  }
  .card-body {
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(#000, 0.3);
    background: #fff;
    margin: 80px 40px 0 -210px;
    padding: 70px 70px 70px;
    @include max($xxl) {
      padding: 40px;
      margin: 10px;
    }
    @include max($lg) {
      padding: 30px;
      margin: 0;
    }
    @include max($sm) {
      padding: 20px;
    }
    .card-logo {
      line-height: 0;
      margin-bottom: 20px;
      min-height: 160px;
      display: flex;
      align-items: center;
      @include max($huge) {
        min-height: 0;
      }
      img {
        max-height: 100px;
      }
    }
    h3 {
      font-size: 32px;
      border-bottom: 4px solid $primary;
      padding: 0 0 10px;
      margin: 0 0 38px;
      @include max($xxl) {
        font-size: 24px;
        padding: 0 0 5px;
        margin-bottom: 20px;
      }
      @include max($sm) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      @include max($lg) {
        font-size: 16px;
      }
    }
    .card-company {
      background: $primary;
      padding: 4px 6px;
      line-height: 22px;
      font-weight: bold;
      border-radius: 3px;
      display: inline-block;
    }
  }
}

.card.card--info {
  border-radius: 25px;
  background: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  padding: 30px;
  @include max($lg) {
    padding: 30px;
  }
  @include max($sm) {
    padding: 20px;
    border-radius: 20px;
  }
}

.card.card--post {
  border-radius: 20px;
  background: #fff;
  padding: 25px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 0 10px rgba(#000, 0.17);
  }
  .card-img {
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 0.9;
      }
      img {
        border-radius: 10px;
        width: 100%;
      }
    }
  }

  .card-body {
    position: relative;
    padding: 25px 0 70px;
    flex: 1;
    .card-tags {
      margin-bottom: 25px;
      @include max($xxl) {
        margin-bottom: 10px;
      }
    }
    .card-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 20px;
      @include max($xxl) {
        font-size: 24px;
        margin-bottom: 10px;
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }

    .card-desc {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 20px;
      @include max($xxl) {
        font-size: 16px;
      }
    }

    .card-date {
      font-size: 24px;
      color: #777777;
      display: block;
      font-weight: 500;
      margin-bottom: 20px;
      @include max($huge) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .btn {
        text-transform: none;
        font-size: 22px;
        font-weight: 500;
        padding: 7px 30px;
      }
    }
  }
}

.card.card--post-featured {
  border-radius: 20px;
  background: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @include max($huge) {
    padding: 20px;
  }
  @include max($md) {
    border-radius: 10px;
  }
  .card-img {
    width: 790px;
    flex: 0 0 790px;
    display: block;
    line-height: 0;
    @include max($huge) {
      width: 50%;
      flex: 0 0 50%;
    }
    @include max($md) {
      width: 100%;
      flex: auto;
      margin: 0 0 20px;
    }

    a {
      display: block;
      line-height: 0;
      img {
        border-radius: 20px;
        width: 100%;
        @include max($md) {
          border-radius: 10px;
          width: 100%;
        }
      }
    }
  }

  .card-body {
    flex: 1;
    padding: 40px;
    @include max($huge) {
      padding: 0 0 0 30px;
    }
    @include max($md) {
      padding: 0;
    }
    .card-tags {
      margin-bottom: 25px;
      @include max($xxl) {
        margin-bottom: 10px;
      }
    }
    .card-title {
      font-size: 36px;
      font-weight: 500;
      line-height: 1.125;
      margin-bottom: 25px;
      @include max($xxl) {
        font-size: 30px;
        margin-bottom: 20px;
      }
      @include max($lg) {
        font-size: 32px;
      }
      @include max($sm) {
        font-size: 27px;
        margin-bottom: 15px;
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }

    .card-desc {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 30px;
      @include max($xxl) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .card-date {
      font-size: 24px;
      color: #777777;
      margin-bottom: 20px;
      display: block;
      font-weight: 500;
      @include max($xxl) {
        font-size: 20px;
      }
    }

    .btns {
      .btn {
        font-size: 30px;
        padding: 12px 30px;
        text-transform: none;
        font-weight: 500;
        @include max($xxl) {
          font-size: 22px;
          padding: 7px 30px;
        }
      }
    }
  }
}

.card.card--post-wide {
  background: #f8f8f8;
  padding: 25px;
  border-radius: 20px;
  display: flex;
  gap: 25px;
  transition: box-shadow 0.05s;
  @include max($lg) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
  &:hover {
    box-shadow: 0 0 10px rgba(#000, 0.17);
  }
  .card-img {
    line-height: 0;
    flex: 0 0 500px;
    width: 500px;
    @include max($lg) {
      width: 100%;
      flex: auto;
    }
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 0.9;
      }
      img {
        border-radius: 10px;
      }
    }
  }

  .card-body {
    flex: 1;
    .card-tags {
      margin-bottom: 25px;
      @include max($huge) {
        margin-bottom: 10px;
      }
    }
    .card-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 20px;
      @include max($huge) {
        font-size: 24px;
        margin-bottom: 10px;
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }
    .card-desc {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 1.2;
      @include max($huge) {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
    .card-date {
      color: #777777;
      font-size: 22px;
      display: block;
      font-weight: 500;
      margin-bottom: 20px;
      @include max($huge) {
        font-size: 18px;
      }
    }
    .btn {
      text-transform: none;
      font-size: 22px;
      font-weight: 500;
      padding: 7px 30px;
    }
  }
}

.card.card--timeline {
  box-shadow: 0 0 10px rgba(#000, 0.17);
  border-radius: 35px;
  background: #fff;
  padding: 45px;
  display: flex;
  align-items: center;
  @include max($xxl) {
    padding: 30px;
  }
  @include max($lg) {
    padding: 20px;
  }
  @include max($sm) {
    flex-direction: column;
  }
  .card-img {
    width: 44%;
    margin-right: 45px;
    @include max($xxl) {
      margin-right: 30px;
    }
    @include max($lg) {
      margin-right: 20px;
    }
    @include max($sm) {
      width: 100%;
      margin: 0 0 20px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      border-radius: 30px;
      width: 100%;
      @include max($sm) {
        border-radius: 20px;
      }
    }
  }
  .card-body {
    flex: 1;
  }
  .card-title {
    display: inline-block;
    font-size: 48px;
    font-weight: bold;
    background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
    border-radius: 10px;
    padding: 10px 30px;
    line-height: 1.125;
    margin-bottom: 55px;
    @include max($huge) {
      font-size: 42px;
      margin-bottom: 40px;
      padding: 8px 20px;
    }
    @include max($xxl) {
      font-size: 36px;
      margin-bottom: 30px;
      padding: 6px 20px;
    }
    @include max($lg) {
      margin-bottom: 20px;
      font-size: 32px;
    }
    @include max($sm) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  .card-desc {
    font-size: 28px;
    line-height: 1.2;
    @include max($huge) {
      font-size: 24px;
    }
    @include max($xxl) {
      font-size: 20px;
    }
    @include max($lg) {
      font-size: 18px;
    }
    @include max($sm) {
      font-size: 16px;
    }
  }
}
.card.card--company-slider {
  box-shadow: 0 0 10px rgba(#000, 0.17);
  border-radius: 35px;
  background: #fff;
  padding: 45px;
  display: flex;
  align-items: center;
  @include max($xxl) {
    padding: 30px;
  }
  @include max($lg) {
    padding: 20px;
  }
  @include max($sm) {
    flex-direction: column;
  }
  .card-img {
    width: 44%;
    margin-right: 45px;
    @include max($xxl) {
      margin-right: 30px;
    }
    @include max($lg) {
      margin-right: 20px;
    }
    @include max($sm) {
      width: 100%;
      margin: 0 0 20px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      border-radius: 20px;
      width: 100%;
      @include max($sm) {
        border-radius: 20px;
      }
    }
  }
  .card-body {
    flex: 1;
  }
  .card-title {
    display: inline-block;
    font-size: 48px;
    font-weight: bold;
    background: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
    border-radius: 20px;
    padding: 10px 30px;
    line-height: 1.125;
    margin-bottom: 55px;
    max-width: 600px;
    @include max($huge) {
      font-size: 42px;
      margin-bottom: 40px;
      padding: 8px 20px;
    }
    @include max($xxl) {
      font-size: 36px;
      margin-bottom: 30px;
      padding: 6px 20px;
    }
    @include max($lg) {
      margin-bottom: 20px;
      font-size: 32px;
    }
    @include max($sm) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  .card-desc {
    font-size: 24px;
    line-height: 1.2;
    @include max($huge) {
      font-size: 24px;
    }
    @include max($xxl) {
      font-size: 20px;
    }
    @include max($lg) {
      font-size: 18px;
    }
    @include max($sm) {
      font-size: 16px;
    }
  }
}

.card.card--sector {
  background: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 32px 40px;
  @include max($xxl) {
    padding: 30px;
  }
  @include max($lg) {
    padding: 20px;
    align-items: flex-start;
  }
  @include max($sm) {
    align-items: center;
  }
  .card-icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    padding: 5px;
    border-radius: 10px;
    margin-right: 40px;
    display: flex;
    background: $primary;
    align-items: center;
    justify-content: center;
    @include max($xxl) {
      margin-right: 20px;
    }
    @include max($lg) {
      margin-right: 10px;
    }
    @include max($sm) {
      margin-right: 15px;
    }
    svg {
    }
  }
  .card-body {
    flex: 1;
  }
  .card-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 5px;
    @include max($xxl) {
      font-size: 27px;
    }
    @include max($lg) {
      font-size: 24px;
    }
    @include max($sm) {
      font-size: 22px;
    }
  }
  .card-desc {
    @include max($sm) {
      font-size: 14px;
    }
  }
}

.card.card--person {
  background: rgba(#fff, 0.9);
  padding: 40px 40px 40px;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  border-radius: 20px;
  transition: all 0.2s;
  @include max($xxl) {
    padding: 30px 30px 30px;
  }
  @include max($sm) {
    padding: 20px;
  }
  .card-img {
    margin-bottom: 18px;
    @include max($lg) {
      margin-bottom: 10px;
    }
    img {
      border-radius: 20px;
      transition: all 0.2s;
      filter: grayscale(100%);
      width: 100%;
    }
  }
  .card-body {
  }
  .card-name {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    @include max($lg) {
      font-size: 24px;
    }
    @include max($sm) {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  .card-position {
    font-size: 20px;
    line-height: 1.2;
    min-height: 3.6em;
    @include max($lg) {
      font-size: 18px;
    }
    @include max($sm) {
      font-size: 16px;
    }
  }
}

.tag-list {
  ul {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 10px;
    @include max($xxl) {
    }
    li {
      margin: 0;
      padding: 0;
      a {
        display: block;
        background: $primary;
        padding: 5px 6px;
        line-height: 20px;
        border-radius: 3px;
        font-size: 14px;
        color: $black;
        @include max($lg) {
          padding: 3px 6px;
        }
        &:hover {
          background: $primaryhover;
        }
      }
    }
  }
  &.tag-list-lg {
    ul {
      @include min($huge) {
        gap: 15px;
      }
      li {
        a {
          @include min($huge) {
            padding: 5px 10px;
            line-height: 28px;
            border-radius: 5px;
            font-size: 22px;
            font-weight: 500;
          }
        }
      }
    }
  }
  &.tag-list-md {
    ul {
      @include min($huge) {
        gap: 15px;
      }
      li {
        a {
          @include min($huge) {
            padding: 5px 10px;
            line-height: 23px;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.card.card--adv {
  height: 235px;
  background: #fff;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px 20px;
  @include max($huge) {
    height: auto;
  }
  @include max($lg) {
    padding: 30px 15px 10px;
  }
  @include max($sm) {
    padding: 15px;
    border-radius: 20px;
  }
  .card-val {
    background-image: linear-gradient(to right, #f5b800, #ffd147);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
    font-size: 60px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    @include max($huge) {
      font-size: 48px;
    }
    @include max($xxl) {
      font-size: 42px;
    }
    @include max($lg) {
      font-size: 36px;
    }
    @include max($sm) {
      font-size: 30px;
    }
  }
  .card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    min-height: 2.4em;
    @include max($xxl) {
      font-size: 18px;
    }
    @include max($sm) {
      font-size: 16px;
      min-height: 0;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;
  @include max($lg) {
    gap: 5px;
  }
  > a,
  > span {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    border-radius: 5px;
    @include max($lg) {
      width: 28px;
      height: 28px;
      font-size: 16px;
    }
  }
  > a {
    &:hover {
      background: #f2f2f2;
    }
    &.page-prev,
    &.page-next {
      width: 42px;
      height: 42px;
      padding: 5px;
      &:hover {
        opacity: 0.7;
        background: none;
      }
      @include max($lg) {
        width: 28px;
        height: 28px;
      }
      svg {
        height: 16px;
        width: 16px;
      }
    }
    &.page-prev {
      margin-right: 10px;
    }
    &.page-next {
      margin-left: 10px;
    }
  }
  > span {
    background: $black;
    color: #fff;
    cursor: default;
    &.ellipsis {
      background: none;
      color: $black;
    }
  }
}

.card.card--benefit {
  background: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.17);
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  padding: 40px 40px;
  @include max($xxl) {
    padding: 30px;
  }
  @include max($lg) {
    padding: 20px;
    align-items: flex-start;
  }
  @include max($sm) {
    align-items: center;
  }
  .card-icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    padding: 5px;
    border-radius: 10px;
    margin-right: 40px;
    display: flex;
    background: $primary;
    align-items: center;
    justify-content: center;
    @include max($xxl) {
      margin-right: 20px;
    }
    @include max($lg) {
      margin-right: 10px;
    }
    @include max($sm) {
      margin-right: 15px;
    }
    svg {
    }
  }
  .card-body {
    flex: 1;
  }
  .card-title {
    font-size: 32px;
    font-weight: normal;
    line-height: 1.1;
    margin-bottom: 25px;
    @include max($xxl) {
      font-size: 27px;
      margin-bottom: 10px;
    }
    @include max($lg) {
      font-size: 24px;
    }
    @include max($sm) {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
  .card-desc {
    font-size: 20px;
    @include max($lg) {
      font-size: 16px;
    }
    @include max($sm) {
      font-size: 14px;
    }
  }
}

.card.card--role {
  background: #f8f8f8;
  border-radius: 15px;
  padding: 35px 45px 45px 40px;
  @include max($huge) {
    padding: 30px;
  }
  @include max($lg) {
    padding: 20px;
  }
  @include max($sm) {
    padding: 20px 15px;
  }
  .card-t {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include max($sm) {
      margin-bottom: 15px;
      display: block;
    }
    .card-position {
      margin-right: 30px;
      font-size: 32px;
      font-weight: bold;
      @include max($xxl) {
        font-size: 24px;
      }
      @include max($sm) {
        font-size: 20px;
        margin: 0 0 0;
      }
    }
    .card-location {
      margin-left: auto;
      font-size: 32px;
      text-align: right;
      @include max($xxl) {
        font-size: 24px;
      }
      @include max($sm) {
        font-size: 20px;
        margin: 0;
        text-align: left;
      }
    }
  }
  .card-b {
    display: flex;
    align-items: flex-end;
    @include max($lg) {
      display: block;
    }
    .card-content {
      flex: 1;
      max-width: 950px;
      @include max($lg) {
        margin: 0 0 20px;
      }
    }
    .card-label {
      font-size: 20px;
      font-weight: 500;
      @include max($lg) {
        font-size: 16px;
      }
    }
    .card-desc {
      font-size: 20px;
      @include max($lg) {
        font-size: 16px;
      }
    }
    .card-btn {
      margin-left: auto;
      @include max($lg) {
        margin: 0;
      }
      .btn {
        width: 230px;
      }
    }
  }
}

.swiper-nav {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  @include max($sm) {
    gap: 10px;
  }
  .swiper-prev,
  .swiper-next {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="553.3" height="969.91" viewBox="0 0 553.3 969.91"><path d="M164.94,485.1,533.3,116.73A68.35,68.35,0,1,0,436.69,20L21.46,435.42A5.69,5.69,0,0,0,20,436.74,67.63,67.63,0,0,0,0,485.1a68.59,68.59,0,0,0,20,48.36,6.8,6.8,0,0,1,1.46,1.31L436.69,949.9a68.26,68.26,0,1,0,96.61-96.46Z"/></svg>')
      50% 50% no-repeat;
    background-size: 19px 33px;
    &:hover {
      background-color: $greyf;
    }
  }
  .swiper-prev {
  }
  .swiper-next {
    transform: scaleX(-1);
  }
}

.card.card-location {
  box-shadow: 0 0 10px rgba(#000, 0.17);
  background: #fff;
  border-radius: 15px;
  .card-img {
    width: 100%;
    line-height: 0;
    img {
      border-radius: 15px;
    }
  }
  .card-body {
    padding: 30px;
    @include max($xxl) {
      padding: 20px;
    }
  }
  .card-tag {
    background: $primary;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: bold;
  }
  .card-title {
    font-size: 32px;
    margin-bottom: 2px;
    font-weight: bold;
    line-height: 1.2;
    @include max($lg) {
      font-size: 24px;
    }
  }
  .card-desc {
    font-size: 28px;
    @include max($xxl) {
      font-size: 24px;
    }
    @include max($lg) {
      font-size: 20px;
    }
    @include max($xs) {
      font-size: 16px;
    }
  }
}
