/***** Footer *****/

.footer {
  .footer-demo {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $primary;
      border-radius: 24px 24px 0 0;
      z-index: 0;
      height: 183px;
      @include max($huge) {
        height: auto;
        top: 122px;
      }
      @include max($xxl) {
        top: 92px;
      }
      @include max($sm) {
        top: 80px;
      }
    }
    .demo-i {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-start;
      padding: 0 110px 0 15px;
      @include max($huge) {
        padding: 0 15px;
      }
      @include max($lg) {
        flex-direction: column;
        padding: 0;
      }
    }
    .demo-txt {
      padding: 173px 0 41px;
      @include max($huge) {
        padding: 42px 0 0;
      }
      @include max($lg) {
        padding: 42px 0 0;
      }
      @include max($sm) {
        padding: 42px 0 0;
      }
      .demo-txt-t {
        font-size: 48px;
        line-height: 1;
        font-weight: 500;
        @include max($xxl) {
          font-size: 36px;
        }
        @include max($sm) {
          font-size: 27px;
        }
        .highlight {
          line-height: 0.9;
        }
      }
      .demo-txt-b {
        font-size: 32px;
        @include max($xxl) {
          font-size: 24px;
        }
        @include max($sm) {
          font-size: 18px;
        }
      }
      .btns {
        margin-top: 32px;
        @include max($xxl) {
          margin-top: 20px;
        }
        .btn {
        }
      }
    }

    .demo-img {
      margin-left: auto;
      line-height: 0;
      @include max($xxl) {
        margin: 0;
      }
      img {
      }
    }
  }

  .footer-c {
    background: $primary;
    .footer-c-i {
      background: #000;
      color: #fff;
      border-radius: 20px;
      padding: 20px 20px 35px;
      a {
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .footer-logo {
      margin-bottom: 35px;
      line-height: 0;
      img {
        width: 142px;
      }
    }
    .footer-c-row {
      display: flex;
      align-items: flex-start;
      margin: 0 -40px;
      flex-wrap: wrap;
      @include max($huge) {
        margin: 0 -20px;
      }
    }
    .footer-c-l {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 40px;
      @include max($huge) {
        padding: 0 20px;
      }
      @include max($xxl) {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 40px;
      }
    }
    .footer-c-r {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 40px;
      @include max($huge) {
        padding: 0 20px;
      }
      @include max($xxl) {
        width: 100%;
        flex: 0 0 100%;
      }
    }

    .footer-block {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      h3 {
        font-size: 16px;
        border-bottom: 2px solid #fff;
        margin: 0 0 18px;
        padding-bottom: 8px;
      }
      .block-body {
      }
      .footer-menu {
        ul {
          list-style: none;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 10px 30px;
          padding: 0;
          margin: 0;
          @include max($huge) {
            gap: 10px 20px;
          }
          li {
            margin: 0;
            padding: 0;
            font-weight: bold;
            a {
            }
          }
        }
      }
      .social-menu {
        ul {
          list-style: none;
          display: flex;
          align-items: flex-start;
          gap: 20px;
          padding: 0;
          margin: 0;
          li {
            margin: 0;
            padding: 0;
            a {
              color: #fff;
              line-height: 0;
              height: 21px;
              display: block;
              &:hover {
                opacity: 0.8;
              }
              .icon {
                max-width: 21px;
                max-height: 21px;
                fill: currentColor;
                &.icon-youtube {
                  max-width: 29px;
                }
              }
            }
          }
        }
      }
      .contact-items {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px 30px;
        .contact-item {
          strong {
          }
          a {
          }
        }
      }
      .footer-partners {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: flex-start;
        img {
        }
      }
    }
  }

  .footer-b {
    background: $primary;
    padding: 15px 0 55px;
    @include max($md) {
      padding: 10px 0 20px;
    }
    .footer-b-i {
      display: flex;
      align-items: center;
      @include max($md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .footer-b-copy {
      padding-right: 30px;
    }
    .footer-b-menu {
      margin-left: auto;
      @include max($md) {
        margin: 10px 0 0;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 25px;
        @include max($md) {
          gap: 20px;
        }
        li {
          margin: 0;
          a {
            // font-weight: bold;
          }
        }
      }
    }
  }
}
