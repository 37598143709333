/**** Form elements *****/

.form-group {
  margin-bottom: 30px;
  label {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: bold;
    .req {
      color: #e85353;
    }
  }
}
.form-control {
  border: 1px solid #979797;
  background: #fff;
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 16px;
  &.form-control--sm {
    padding: 7px 10px;
    line-height: 20px;
    font-size: 16px;
    font-size: 12px;
    border-color: #cbcbcb;
  }
}
input.form-control.form-control--sm {
  height: 36px;
}

.select-container {
  position: relative;
  border: 2px solid #979797;
  border-radius: 12px;
  background: #fff;
  max-width: 100%;
  overflow: hidden;
  height: 59px;
  display: flex;
  align-items: stretch;
  transition: all 0.3s;
  @include max($xxl) {
    border-width: 1px;
    height: 50px;
  }
  &.focused {
    border-color: #777;
  }
  @include max($xxxs) {
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 21px;
    top: 24px;
    width: 16px;
    height: 11px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10.31" viewBox="0 0 16 10.31"><path d="M.29,1.93l6.82,8a1.18,1.18,0,0,0,1.78,0l6.83-8A1.17,1.17,0,0,0,14.83,0H1.17A1.16,1.16,0,0,0,.29,1.93Z" style="fill:%23353535"/></svg>')
      50% 50% no-repeat;
    background-size: contain;
    pointer-events: none;
    @include max($lg) {
      right: 15px;
      top: 19px;
    }
  }
  select {
    appearance: none;
    border: none;
    background: none;
    font-size: 20px;
    font-weight: normal;
    padding: 10px 45px 8px 20px;
    height: 55px;
    line-height: 25px;
    width: 100%;
    @include max($xxl) {
      height: 48px;
      padding: 10px 40px 10px 10px;
      line-height: 28px;
      font-size: 16px;
    }
  }
}

.select-container.select-container--sm {
  border-width: 1px;
  border-radius: 5px;
  height: 36px;
  font-size: 12px;
  border-color: #cbcbcb;
  &:after {
    width: 10px;
    height: 7px;
    right: 13px;
    top: 14px;
  }
  select {
    height: 34px;
    padding: 7px 24px 7px 10px;
    font-size: 12px;
    line-height: 20px;
  }
}

/* Selectric */

.selectric {
  border-color: transparent;
  background: none;
  .label {
    font-size: 24px;
    color: $black;
    margin: 0 38px 0 0;
    font-weight: 500;
    @include max($xxl) {
      font-size: 20px;
    }
  }
  .button {
    background: none;
    transition: all 0.1s;
    &:after {
      border: none;
      width: 38px;
      height: 38px;
      background: url("../images/icons/dropdown.svg") 50% 50% no-repeat;
      background-size: 11px 7px;
    }
  }
}
.selectric-items {
  background: #f3f3f3;
  border: none;
  border-radius: 0;
  ul {
    li {
      font-size: 20px;
      white-space: nowrap;
      transition: all 0.1s;
      @include max($xxl) {
        font-size: 16px;
      }
      &:hover {
        background: #eee;
      }
      &.selected {
      }
      &.highlighted {
        background: #ddd;
      }
    }
  }
}
.selectric-wrapper.selectric-hover,
.selectric-wrapper.selectric-open,
.selectric-wrapper.selectric-focus {
  .selectric {
    border-color: transparent;
  }
}
.selectric-wrapper.selectric-default {
  .selectric {
    .label {
      color: #979797;
    }
    .button {
      filter: invert(81%) sepia(0%) saturate(125%) hue-rotate(142deg)
        brightness(75%) contrast(84%);
    }
  }
}

/*******/

.form-check {
  margin-bottom: 30px;
  &.form-check--sm {
    font-size: 14px;
    line-height: 16px;
    input[type="checkbox"] {
      + span {
        &:before {
          content: "";
          border: 1px solid #cbcbcb;
        }
      }
    }
  }
}
.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    + span {
      position: relative;
      padding: 9px 39px 9px 9px;
      border-radius: 4px;
      border: 1px solid #cbcbcb;
      display: block;

      font-weight: bold;
      &:before {
        content: "";
        border: 1px solid #979797;
        border-radius: 4px;
        background: #fff;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 9px;
        top: 9px;
        box-sizing: border-box;
      }
    }
    &:checked {
      + span {
        background: $primary;
        border-color: $primary;
        &:before {
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 9px;
          right: 9px;
          width: 16px;
          height: 16px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 1000 1000"><path d="M846.13,0H153.87A154.08,154.08,0,0,0,0,153.87V846.13A154.08,154.08,0,0,0,153.87,1000H846.13A154.08,154.08,0,0,0,1000,846.13V153.87A154.08,154.08,0,0,0,846.13,0ZM760.19,332.44,437.12,717.06a38.13,38.13,0,0,1-12.93,10,37.57,37.57,0,0,1-15.88,3.69h-.69A38.67,38.67,0,0,1,392,727.44,37.89,37.89,0,0,1,379.06,718L240.62,564.19a38.41,38.41,0,0,1-8.06-12.94,37.46,37.46,0,0,1-2.37-15,38,38,0,0,1,12.68-27.06,38,38,0,0,1,28.19-9.75,37.46,37.46,0,0,1,14.69,3.93,39.21,39.21,0,0,1,12,9.38L406.63,633.69,701.31,282.94a38.37,38.37,0,0,1,53.81-4.25,38.41,38.41,0,0,1,5.07,53.75Z" style="fill:%23353535"/></svg>');
          background-size: contain;
        }
      }
    }
    &:focus {
      + span {
        &:before {
          background: #f5f5f5;
        }
      }
    }
    &:not(:disabled) {
      span {
        &:hover {
          &:before {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}

// .custom-radio {
//   input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     z-index: -1;
//     + span {
//       position: relative;
//       padding: 0 0 0 28px;
//       display: block;
//       &:before {
//         content: '';
//         display: block;
//         border: 1px solid #000;
//         border-radius: 50%;
//         background: #E5E5E5;
//         box-shadow: 1px 1px 0 #A6A6A6 inset;
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         left: 0;
//         top: 2px;
//         box-sizing: border-box;
//       }
//       &:after {
//         content: "";
//         display: none;
//         position: absolute;
//         left: 4px;
//         top: 6px;
//         width: 8px;
//         height: 8px;
//         background: $red;
//         border-radius: 50%;
//         z-index: 1;
//       }
//     }
//     &:checked {
//       + span {
//         &:before {

//         }
//         &:after {
//           display: block;
//         }
//       }
//     }
//     &:focus {
//       + span {
//         &:before {
//           background: #f5f5f5;
//         }
//       }
//     }
//     &:not(:disabled) {
//       span {
//         &:hover {
//           &:before {
//             background: #f5f5f5;
//           }
//         }
//       }
//     }
//   }

//   input[type="radio"] {
//     & + span.radio-info {
//       font-weight: normal;
//       &:before {
//         top: 4px;
//       }
//       &:after {
//         top: 8px;
//       }
//       .radio-info-h {
//         display: flex;
//         align-items: center;
//         margin-bottom: 5px;
//         color: #000;
//         font-size: 19px;
//         font-weight: normal;
//         line-height: 24px;
//         span {}
//         i.icon {
//           margin-left: 7px;
//         }
//       }
//       .radio-info-desc {
//         display: block;
//         font-size: 16px;
//         color: $dgrey;
//       }
//     }
//   }
// }
