/*** Contact ***/

.section-contact {
  padding: 150px 0 80px;
  @include max($xxl) {
    padding: 80px 0 40px;
  }
  .contact-top {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px 100px;
    @include max($huge) {
      margin: 0 -20px 60px;
    }
    @include max($lg) {
      margin: 0 0 40px;
    }
  }
  .contact-img {
    width: 50%;
    flex: 0 0 50%;
    padding: 0 50px;
    @include max($huge) {
      padding: 0 20px;
    }
    @include max($lg) {
      order: 2;
      width: 100%;
      flex: 0 0 100%;
      padding: 0;
    }
    img {
      width: 100%;
      border-radius: 30px;
      @include max($lg) {
        border-radius: 20px;
        height: 60vw;
        object-fit: cover;
      }
    }
  }
  .contact-top-r {
    width: 50%;
    flex: 0 0 50%;
    padding: 0 50px;
    @include max($huge) {
      padding: 0 20px;
    }
    @include max($lg) {
      order: 1;
      width: 100%;
      flex: 0 0 100%;
      padding: 0;
      margin-bottom: 40px;
    }
    h1 {
      font-family: $font-basic;
      font-weight: bold;
      font-size: 46px;
      margin: 0 0 10px;
      @include max($huge) {
        font-size: 42px;
      }
      @include max($xxl) {
        font-size: 36px;
      }
      @include max($sm) {
        font-size: 30px;
      }
    }
    .contact-desc {
      font-size: 20px;
      margin-bottom: 40px;
      @include max($lg) {
        font-size: 16px;
      }
    }
    .contact-form-block {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
      border-radius: 20px;
      margin-bottom: 50px;
      @include max($huge) {
        margin-bottom: 30px;
      }
    }
    .contact-info-block {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
      padding: 30px;
      border-radius: 20px;
    }
    .contact-info {
      display: flex;
      align-items: center;
      .icon-w {
        width: 70px;
        height: 70px;
        border-radius: 12px;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 70px;
        margin-right: 24px;
        .icon {
        }
      }
      .contact-info-r {
        flex: 1;
        h2 {
          font-size: 24px;
          margin: 0 0 5px;
        }
        p {
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 0 10px;
          &:not(:last-child) {
            @include max($sm) {
              margin-bottom: 10px;
            }
          }
          span {
            width: 120px;
            flex: 0 0 120px;
          }
          a {
            text-decoration: underline;
            font-weight: 500;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .contact-locations {
    h2 {
      margin-bottom: 30px;
    }
  }

  .location-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
    .location-item {
      padding: 0 15px 30px;
      width: 33.3333%;
      flex: 0 0 33.3333%;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      &.location-item--wide {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
