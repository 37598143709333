/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1660px;
  @include max($huge) {
    padding: 0 40px;
  }
  @include max($xxl) {
    padding: 0 30px;
  }
  @include max($sm) {
    padding: 0 20px;
  }
}

.main {
  // margin-top: 85px;
  // @include max($xxl) {
  //   margin-top: 57px;
  // }
}

.row--2-col {
  margin: 0 -30px;
  @include max($xxl) {
    margin: 0 -20px;
  }
  @include max($sm) {
    margin: 0 -15px;
  }
}
.col--content {
  width: 996px;
  flex: 0 0 996px;
  padding: 0 30px;
  @include max($xhuge) {
    width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  @include max($huge) {
  }
  @include max($xxl) {
    padding: 0 20px;
    flex: 0 0 100%;
  }
  @include max($sm) {
    padding: 0 15px;
  }
}
.col--sidebar {
  width: 664px;
  flex: 0 0 664px;
  padding: 0 30px;
  @include max($xhuge) {
    width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  @include max($xxl) {
    padding: 0 20px;
    flex: 0 0 100%;
  }
  @include max($sm) {
    padding: 0 15px;
  }
}
