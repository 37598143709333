.section-txt {
  padding: 225px 0 140px;
  @include max($huge) {
    padding: 185px 0 100px;
  }
  @include max($xxl) {
    padding: 117px 0 60px;
  }
  @include max($sm) {
    padding: 97px 0 40px;
  }
  .col--sidebar {
    margin-top: 62px;
    @include max($xxl) {
      margin-top: 0;
    }
  }
  .bnr-block {
    min-height: 423px;
    background: #e5e5e5;
    margin-bottom: 80px;
    @include max($xxl) {
      margin-bottom: 40px;
    }
  }

  .dots {
    height: 469px;
    background: url("../images/el/dots-bg.png") 0 0 no-repeat;
    background-size: contain;
  }

  .txt-right-col {
    padding-left: 45px;
    @include max($xxl) {
      padding: 0;
    }
  }

  .table {
    margin: 120px 0;
    @include max($huge) {
      margin: 100px 0;
    }
    @include max($xxl) {
      margin: 60px 0;
    }
    @include max($sm) {
      margin: 40px 0;
    }
  }
}
