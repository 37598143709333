/*** Standalone Contact Form ***/

.contact-form {
  padding: 26px 30px 30px;
  h2 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 26px;
  }
  textarea.form-control {
    height: 90px;
  }

  .btn.btn-sm {
    height: 39px;
    min-width: 150px;
  }
}
