/***** Simple elements, atoms *****/

.icon-w {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icon {
    fill: currentColor;
    max-height: 100%;
    max-width: 100%;
  }
}

.txt {
  @include min($xxhuge) {
    font-size: 18px;
  }
  h2,
  .h2 {
    background-image: linear-gradient(to right, #000000 0%, #666 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
    &.h2-yellow {
      background-image: linear-gradient(to right, #f5b800, #ffd147);
    }
  }
  .lead {
    font-size: 48px;
    margin-bottom: 120px;
    line-height: 1.2;
    @include max($huge) {
      font-size: 40px;
      margin-bottom: 100px;
    }
    @include max($xxl) {
      font-size: 32px;
      margin-bottom: 60px;
    }
    @include max($sm) {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  .link-highlight {
    background: rgba($primary, 0.2);
    border-radius: 3px;
    padding: 2px 3px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 2px;
      left: 3px;
      right: 3px;
      height: 2px;
      background: repeating-linear-gradient(
        90deg,
        #808080,
        #808080 4px,
        transparent 4px,
        transparent 6px
      );
    }
    &:hover {
      color: rgba(#000, 0.8);
    }
  }

  .highlight-block {
    background: $primary;
    background: linear-gradient(to right, #f5bb00, #f5ce00);
    color: #fff;
    max-width: 1200px;
    margin: 80px auto 120px;
    padding: 40px 80px;
    text-align: center;
    @include max($huge) {
      margin: 60px 0;
      padding: 30px;
    }
    @include max($xxl) {
      padding: 30px 20px;
    }
    @include max($sm) {
      padding: 20px;
    }
    strong {
      color: #000;
    }
  }
  .dark-block {
    background: #0f0f0f;
    background: linear-gradient(to right, #0f0f0f, #4a4a4a);
    color: #fff;
    max-width: 1200px;
    margin: 80px auto 120px;
    padding: 30px 80px;
    text-align: center;
    @include max($huge) {
      margin: 60px 0;
      padding: 30px;
    }
    @include max($xxl) {
      padding: 30px 20px;
    }
    @include max($sm) {
      padding: 20px;
    }
    strong {
      color: $primary;
    }
  }

  .w-100 {
    width: 100% !important;
    max-width: 100%;
  }

  .btns-row {
    margin-top: 40px;
    margin-bottom: 75px;
    @include max($huge) {
      margin: 40px auto;
    }
    @include max($xxl) {
      margin: 30px auto;
    }
  }
}

.rounded-20 {
  border-radius: 20px;
}
.rounded-28 {
  border-radius: 28px;
}

.highlight {
  background-image: linear-gradient(to right, #f5bb00 0%, #f5ce00 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.cta-block {
  margin: 55px 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.3);
  padding: 30px 40px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  @include max($huge) {
    margin: 40px 0;
    padding: 30px;
  }
  @include max($xxl) {
    margin: 30px 0;
    padding: 30px 20px;
  }
  @include max($sm) {
    padding: 20px;
  }
  .icon-w {
    border-radius: 9px;
    border: 4px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin-right: 40px;
    @include max($xxl) {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      margin-right: 20px;
    }
  }
  .cta-txt {
    flex: 1;
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  &.cta-block--link {
    &:hover {
      box-shadow: 0 0 10px rgba(#000, 0.4);
    }
    .cta-text {
      h3 {
      }
      p {
      }
    }
  }
  &.cta-block--btn {
    @include max($sm) {
      flex-direction: column;
    }
    .cta-txt {
      margin-right: 30px;
      @include max($sm) {
        margin: 0 auto;
        text-align: center;
      }
      h3 {
      }
      .cta-desc {
      }
    }
    .btn {
      margin-left: auto;
      min-width: 231px;
      @include max($sm) {
        margin: 20px auto 0;
      }
    }
  }
}

h3.text-underline {
  border-bottom: 4px solid $primary;
  padding-bottom: 12px;
  margin-bottom: 25px;
}

.d-grid {
  display: grid;
}
.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.font-500 {
  font-weight: 500;
}

.shadow-1 {
  box-shadow: 0 0 10px rgba(#000, 0.3);
}

.is-lg-top {
  @include max($lg) {
    order: -1;
  }
}
.is-md-top {
  @include max($md) {
    order: -1;
  }
}
.is-sm-top {
  @include max($sm) {
    order: -1;
  }
}

.align-items-stretch {
  align-items: stretch;
}

.text-block {
  padding: 40px 40px 55px;
  margin: 60px 0;
  @include max($huge) {
    padding: 40px 30px;
  }
  @include max($xxl) {
    padding: 30px 20px;
  }
  @include max($sm) {
    padding: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      margin: 0 0 15px;
      padding: 0 0 0 20px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 12px;
        height: 12px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="947.5" height="946.7" viewBox="0 0 947.5 946.7"><path d="M947.66,473.35,474,946.7.16,473.35,474,0Zm-235.66,0L474,235,235.83,473.35,474,711.67Z" transform="translate(-0.16)"/></svg>')
          50% 50% no-repeat;
        background-size: contain;
      }
      &:last-child {
        margin: 0;
      }
      ol {
        padding: 0 0 0 20px;
        margin: 25px 0 0;
        li {
          padding: 0 0 0 20px;
          margin: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

ul.hl-list {
  margin: 1.5em 0;
  padding: 0;
  li {
    list-style: none;
    border-radius: 5px;
    background-color: #f5bb00;
    width: fit-content;
    padding: 10px;
    font-weight: bold;
    margin: 0 0 12px;
    @include max($sm) {
      padding: 7px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

ul.dark-list {
  margin: 1.5em 0;
  padding: 0;
  li {
    list-style: none;
    border-radius: 5px;
    background-color: #353535;
    width: fit-content;
    padding: 15px 10px 15px 55px;
    font-weight: bold;
    margin: 0 0 12px;
    color: #fff;
    position: relative;
    @include max($sm) {
      padding: 7px 7px 7px 40px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: calc(50% - 15px);
      left: 15px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M4.58,25.79A15,15,0,0,1,1.12,9.32a14.91,14.91,0,0,1,8.2-8.2A15,15,0,0,1,25.79,4.58,15,15,0,0,1,4.58,25.79Zm19.09-2.12a12,12,0,1,0-8.49,3.52A12,12,0,0,0,23.67,23.67ZM10.23,14.12l3.45,3.46,6.45-6.45,2.1,2.13-8.55,8.52L8.13,16.23l2.1-2.13Z" fill="%23ffffff" /></svg>')
        50% 50% no-repeat;
      background-size: contain;
      @include max($sm) {
        width: 20px;
        height: 20px;
        top: 8px;
        left: 10px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}
ul.white-list {
  margin: 1.5em 0;
  padding: 0;
  li {
    list-style: none;
    border-radius: 10px;
    border: 1px solid #353535;
    width: fit-content;
    padding: 9px 9px 9px 44px;
    font-weight: bold;
    margin: 0 0 12px;
    color: #353535;
    position: relative;
    @include max($sm) {
      padding: 6px 6px 6px 39px;
      border-radius: 8px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: 15px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M4.58,25.79A15,15,0,0,1,1.12,9.32a14.91,14.91,0,0,1,8.2-8.2A15,15,0,0,1,25.79,4.58,15,15,0,0,1,4.58,25.79Zm19.09-2.12a12,12,0,1,0-8.49,3.52A12,12,0,0,0,23.67,23.67ZM10.23,14.12l3.45,3.46,6.45-6.45,2.1,2.13-8.55,8.52L8.13,16.23l2.1-2.13Z" fill="%23353535" /></svg>')
        50% 50% no-repeat;
      background-size: contain;
      @include max($sm) {
        top: 8px;
        left: 10px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}
ul.white-list {
  margin: 1.5em 0;
  padding: 0;
  li {
    list-style: none;
    border-radius: 10px;
    border: 1px solid #353535;
    width: fit-content;
    padding: 9px 9px 9px 44px;
    font-weight: bold;
    margin: 0 0 12px;
    color: #353535;
    position: relative;
    @include max($sm) {
      padding: 6px 6px 6px 39px;
      border-radius: 8px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: 15px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M4.58,25.79A15,15,0,0,1,1.12,9.32a14.91,14.91,0,0,1,8.2-8.2A15,15,0,0,1,25.79,4.58,15,15,0,0,1,4.58,25.79Zm19.09-2.12a12,12,0,1,0-8.49,3.52A12,12,0,0,0,23.67,23.67ZM10.23,14.12l3.45,3.46,6.45-6.45,2.1,2.13-8.55,8.52L8.13,16.23l2.1-2.13Z" fill="%23353535" /></svg>')
        50% 50% no-repeat;
      background-size: contain;
      @include max($sm) {
        top: 8px;
        left: 10px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}

ul.white-list-plain {
  margin: 1.5em 0;
  padding: 0;
  li {
    list-style: none;
    width: fit-content;
    padding: 7px 9px 7px 44px;
    font-weight: bold;
    margin: 0 0 10px;
    color: #353535;
    position: relative;
    @include max($sm) {
      padding: 6px 6px 6px 39px;
      border-radius: 8px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: 15px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M4.58,25.79A15,15,0,0,1,1.12,9.32a14.91,14.91,0,0,1,8.2-8.2A15,15,0,0,1,25.79,4.58,15,15,0,0,1,4.58,25.79Zm19.09-2.12a12,12,0,1,0-8.49,3.52A12,12,0,0,0,23.67,23.67ZM10.23,14.12l3.45,3.46,6.45-6.45,2.1,2.13-8.55,8.52L8.13,16.23l2.1-2.13Z" fill="%23353535" /></svg>')
        50% 50% no-repeat;
      background-size: contain;
      @include max($sm) {
        top: 8px;
        left: 10px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}

.grey-bg-block {
  position: relative;
  padding: 60px 0;
  &:before {
    content: "";
    display: block;
    background: #f3f3f3;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100vw;
    padding: 40px 0;
    z-index: 0;
  }
  > * {
    position: relative;
    z-index: 1;
  }
}

.table {
  @include max($sm) {
    overflow-x: auto;
  }
  table {
    border: none;
    @include max($sm) {
      width: 100%;
    }
    th {
      border: none;
      border-bottom: 10px solid #fff;
      font-size: 24px;
      font-weight: bold;
      background: $primary;
      padding: 15px 5px 15px 40px;
      white-space: nowrap;
      line-height: 32px;
      text-align: left;
      @include max($huge) {
        white-space: normal;
        font-size: 20px;
        padding: 10px;
        line-height: 1.2;
      }
      @include max($xxl) {
        font-size: 16px;
      }
      @include max($sm) {
        font-size: 14px;
        padding: 10px 5px;
      }
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
        padding-right: 80px;
        @include max($huge) {
          padding-right: 10px;
        }
        @include max($sm) {
          padding-right: 5px;
        }
      }
    }
    td {
      border-width: 0 0 2px;
      border-style: solid;
      border-color: $primary;
      padding: 25px 5px 15px 40px;
      @include max($huge) {
        padding: 10px;
      }
      @include max($sm) {
        padding: 10px 5px;
        font-size: 14px;
      }
      &:last-child {
        padding-right: 80px;
        @include max($huge) {
          padding-right: 10px;
        }
        @include max($sm) {
          padding-right: 5px;
        }
      }
    }
  }
}
